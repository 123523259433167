const KlarnaCardIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="27"
      viewBox="0 0 48 27"
      {...props}
    >
      <title>Klarna</title>
      <rect width="48" height="27" rx="5" fill="#ffb3c7" />
      <path
        d="M40.276,14.557a1.055,1.055,0,1,0,.745.312,1.055,1.055,0,0,0-.745-.312Zm-3.458-.819A1.519,1.519,0,1,0,35.3,15.189a1.485,1.485,0,0,0,1.516-1.451Zm.006-2.822H38.5v5.644H36.824V16.2a2.918,2.918,0,0,1-1.66.517,2.977,2.977,0,0,1,0-5.954,2.911,2.911,0,0,1,1.66.517Zm-13.4.735v-.734H21.711v5.643h1.718V13.924c0-.889.954-1.366,1.617-1.366h.02V10.917A2.08,2.08,0,0,0,23.426,11.651Zm-4.27,2.087a1.519,1.519,0,1,0-1.516,1.451A1.485,1.485,0,0,0,19.155,13.738Zm.006-2.822h1.675v5.644H19.161V16.2a2.919,2.919,0,0,1-1.66.517,2.977,2.977,0,0,1,0-5.954,2.911,2.911,0,0,1,1.66.517Zm10.078-.151a2.028,2.028,0,0,0-1.725.787v-.635H25.847v5.643h1.688V13.594a1.185,1.185,0,0,1,1.257-1.279c.735,0,1.159.444,1.159,1.267v2.977h1.672V12.971a2.222,2.222,0,0,0-2.384-2.206ZM12.112,16.559h1.753V8.4H12.112Zm-7.7,0H6.269V8.4H4.414ZM10.905,8.4a6.52,6.52,0,0,1-1.9,4.632l2.565,3.529H9.28L6.493,12.727l.72-.544A4.7,4.7,0,0,0,9.088,8.4H10.9Z"
        transform="translate(0.707 0.547)"
        fill="#0a0b09"
      />
    </svg>
  )
}

export default KlarnaCardIcon

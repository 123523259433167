import { FC } from 'react'
import { useNavbarContext } from '@contexts/NavBarContext'
import Drawer from '@components/ui/Drawer'
import MainMenuNav from '../Navbar/MainMenuNav'
import { CategoriesDrawer } from '@components/categoriesSidebar'
import { CartView } from '@components/cart'

const Drawers: FC<React.PropsWithChildren<unknown>> = () => {
  const {
    mainMenuOpen,
    setMainMenuOpen,
    categoryTreeOpen,
    setCategoryTreeOpen,
    cartOpen,
    setCartOpen,
  } = useNavbarContext()

  return (
    <>
      <Drawer isOpen={mainMenuOpen} onClose={() => setMainMenuOpen(false)}>
        <MainMenuNav
          closeMobileMenu={() => setMainMenuOpen(false)}
          openCategoriesDrawer={() => {
            setMainMenuOpen(false)
            setCategoryTreeOpen(true)
          }}
        />
      </Drawer>
      <Drawer
        isOpen={categoryTreeOpen}
        onClose={() => setCategoryTreeOpen(false)}
        theme="light"
      >
        <CategoriesDrawer />
      </Drawer>
      <Drawer
        isOpen={cartOpen}
        onClose={() => setCartOpen(false)}
        theme="light"
        from="right"
      >
        <CartView />
      </Drawer>
    </>
  )
}

export default Drawers

import { FC } from 'react'
import Link from 'next/link'

interface Link {
  text: string
  href: string
}

interface FooterLinksBlockProps {
  title: string
  links: Link[]
}

const isExternalLink = (href: string) =>
  href.startsWith('http://') || href.startsWith('https://')

const FooterLinksBlock: FC<React.PropsWithChildren<FooterLinksBlockProps>> = ({
  title,
  links,
}) => (
  <div className="flex flex-col space-y-2.5">
    <p className="text-xs uppercase text-gray-300">{title}</p>

    <ul className="flex flex-col space-y-3">
      {links.map((link) => (
        <li key={link.href}>
          {isExternalLink(link.href) ? (
            <a
              href={link.href}
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer text-base text-white"
            >
              {link.text}
            </a>
          ) : (
            <Link href={link.href}>
              <a className="text-base text-white">{link.text}</a>
            </Link>
          )}
        </li>
      ))}
    </ul>
  </div>
)

export default FooterLinksBlock

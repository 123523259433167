import { CSSProperties, FC, useState } from 'react'
import { useTranslation } from 'next-i18next'
import s from './ProductImage.module.css'
import cn from 'classnames'
import Image from 'next/image'
import { getImgProxyUrl } from '@lib/images/utils'

interface Props {
  productName: string
  productType?: string
  authorName?: string
  imageUrl?: string
  size?: 'sm' | 'lg' | 'productGrid' | 'homepageSlider'
  priority?: boolean
  className?: string
  srcWidth?: number
  srcHeight?: number
  rounded?: boolean
  dynamicHeight?: boolean
  transparentBg?: boolean
  loadingMode?: 'eager' | 'lazy'
}

const PLACEHOLDER_IMG = '/product-img-placeholder.png'
const IMG_SIZES = {
  sm: '100px',
  lg: '300px',
  productGrid: '(max-width: 360px) 152px, 290px',
  homepageSlider: '(max-width: 600px) 200px, 370px',
}

const ProductImage: FC<React.PropsWithChildren<Props>> = ({
  productName,
  productType,
  authorName,
  imageUrl,
  size = 'lg',
  priority,
  className,
  rounded = false,
  dynamicHeight = false,
  transparentBg = false,
  loadingMode,
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)
  const [containerStyles, setContainerStyles] = useState<CSSProperties>({})

  let altText = productName
  if (productType) {
    altText += `, ${t(`${productType}_lc`)}`
  }
  if (authorName) {
    altText += ` ${t('by')} ${authorName}`
  }

  if (imageUrl) {
    imageUrl = imageUrl.replace('https:://', 'https://') // Workaround: images' URLs coming with the wrong protocol from the backend
  }

  const CDNLoader = (props: { src: string; width: number }) => {
    return getImgProxyUrl(props.src, ['resize:fit', `width:${props.width}`])
  }

  const onImageLoad = (props: {
    naturalWidth: number
    naturalHeight: number
  }) => {
    setIsLoading(false)
    setIsImageLoaded(true)
    if (!dynamicHeight && props.naturalWidth < props.naturalHeight) {
      const padding = (props.naturalWidth / props.naturalHeight) * 100
      setContainerStyles({ height: '100%', paddingRight: `${padding}%` })
    } else {
      const padding = (props.naturalHeight / props.naturalWidth) * 100
      setContainerStyles({ width: '100%', paddingBottom: `${padding}%` })
    }
  }
  const onImageError = () => {
    setIsLoading(false)
    setHasError(true)
  }

  return (
    <>
      <div
        className={cn(s.imageContainer, className, {
          [s.loading]: isLoading,
          [s.rounded]: rounded,
          [s.contained]: !dynamicHeight,
          [s.transparentBg]: transparentBg,
        })}
        data-is-loaded={isImageLoaded}
      >
        <div className={s.imageContainerInner} style={containerStyles}>
          {hasError && <img alt={altText} src={PLACEHOLDER_IMG} />}
          {!hasError && (
            <Image
              alt={altText}
              src={imageUrl || PLACEHOLDER_IMG}
              layout="fill"
              sizes={IMG_SIZES[size]}
              onLoadingComplete={onImageLoad}
              onError={onImageError}
              loader={CDNLoader}
              priority={priority}
              loading={loadingMode}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default ProductImage

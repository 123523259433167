const UserIcon = ({ ...props }) => {
  return (
    <svg width="19.058" height="20.249" viewBox="0 0 19.058 20.249" {...props}>
      <path
        d="M15.8,15.768a4.97,4.97,0,0,0,4.745-5.157A4.87,4.87,0,0,0,15.8,5.595a4.9,4.9,0,0,0-4.745,5.038A4.964,4.964,0,0,0,15.8,15.768Zm0-1.9a3.031,3.031,0,0,1-2.763-3.239A2.951,2.951,0,0,1,15.8,7.491a2.93,2.93,0,0,1,2.763,3.12A3.045,3.045,0,0,1,15.8,13.872ZM9.039,25.844H22.55c1.874,0,2.774-.6,2.774-1.874,0-2.979-3.716-6.945-9.523-6.945S6.266,20.99,6.266,23.97C6.266,25.248,7.165,25.844,9.039,25.844Zm-.336-1.9c-.26,0-.358-.087-.358-.282,0-1.658,2.676-4.745,7.454-4.745s7.443,3.088,7.443,4.745c0,.2-.1.282-.358.282Z"
        transform="translate(-6.266 -5.595)"
        fill="currentColor"
      />
    </svg>
  )
}

export default UserIcon

import React, { ChangeEvent } from 'react'

import Flag from '@components/icons/Flag'
import { localeType, localeReturnObject } from '@lib/locales/utils'
import styles from './CountryPicker.module.css'

interface Props {
  locales: localeReturnObject[]
  value: localeType | null
  onChange: (locale: localeType) => void // eslint-disable-line no-unused-vars
}

const CountryPicker = ({ locales, value, onChange }: Props) => {
  function handleCountryPickerChange(evt: ChangeEvent<HTMLInputElement>) {
    const value = evt.target.value as localeType

    onChange(value)
  }

  return (
    <ul className={styles.wrapper}>
      {locales.map((localeObject) => (
        <li key={localeObject.locale} className={styles.item}>
          <label className={styles.label}>
            <input
              id="countryPicker"
              type="radio"
              aria-label={`Select ${localeObject.countryName} country`}
              name="country"
              value={localeObject.locale}
              checked={localeObject.locale === value}
              onChange={handleCountryPickerChange}
            />

            <div className={styles.content}>
              <div className={styles.flagWrapper}>
                <Flag locale={localeObject.locale} />
              </div>
              <div className={styles.textWrapper}>
                <p className={styles.countryNameText}>
                  <strong>{localeObject.countryName}</strong>
                </p>
                <p className={styles.countryPathText}>{localeObject.path}</p>
              </div>
            </div>
          </label>
        </li>
      ))}
    </ul>
  )
}

export default CountryPicker

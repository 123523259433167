import { useAuth0 } from '@auth0/auth0-react'
import LogoutButton from './LogoutButton'
import { useTranslation } from 'next-i18next'
import { Button } from '@components/ui'

const UserMenu = () => {
  const { t } = useTranslation()
  const { user } = useAuth0()

  const handleMyAccountClick = () => {
    if (window.analytics) {
      window.analytics.track('Header My Account Clicked')
    }
  }

  if (!user) {
    return null
  }
  return (
    <div className="fixed top-[67px] right-0 z-modal w-full md:absolute md:top-full md:mt-3 md:w-80">
      <div className="bg-black px-5.5 pt-1 pb-3 md:bg-transparent md:p-0">
        <div className="rounded-2xl border border-gray-700 bg-white p-4 text-black">
          <div className="mb-5 flex items-center space-x-3">
            {user.picture && (
              <img
                width={96}
                height={96}
                src={user.picture}
                alt={user.name || 'User avatar'}
                referrerPolicy="no-referrer"
                className="h-16 w-16 rounded-full"
              />
            )}
            <div className="min-w-0">
              <div className="overflow-hidden overflow-ellipsis whitespace-nowrap font-medium">
                {user.name}
              </div>
              {user.name !== user.email && (
                <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-sm">
                  {user.email}
                </div>
              )}
              <LogoutButton>
                <span className="text-sm font-medium underline">
                  {t('logout')}
                </span>
              </LogoutButton>
            </div>
          </div>
          <Button
            Component="a"
            onClick={handleMyAccountClick}
            href={process.env.NEXT_PUBLIC_VAULT_URL}
            variant="primary"
            size="small"
            className="w-full"
          >
            {t('navigation.my_account')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default UserMenu

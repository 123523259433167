import { FC } from 'react'
import { useLocale } from '@lib/locales/utils'
import { useAuth0 } from '@auth0/auth0-react'
import { baseRedirectUri } from '@lib/auth'

interface Props {
  children: React.ReactNode
}

const LogoutButton: FC<React.PropsWithChildren<Props>> = ({ children }) => {
  // TODO - i18n logout... if we have the cookie stored then should redirect
  const { localePrefix } = useLocale()
  const { logout } = useAuth0()

  const handleLogout = () => {
    logout({
      logoutParams: {
        // needs the locale prefix right for this project?
        returnTo: baseRedirectUri,
      },
    })
  }

  return <button onClick={handleLogout}>{children}</button>
}

export default LogoutButton

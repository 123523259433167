import cn from 'classnames'
import s from './Searchbar.module.css'
import { useRouter } from 'next/router'
import { FC, useEffect, useState, useRef, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import { Cross, SearchIcon } from '@components/icons'
import { ChangeEvent } from 'react'
import { useNavbarContext } from '@contexts/NavBarContext'
import { fetchDiscoveryAutocomplete } from '@lib/discoveryFilters/utils'
import { DiscoveryQueryData } from '@lib/discoveryFilters/types'
import debounce from 'lodash.debounce'
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock'
import Link from 'next/link'
import { Container } from '@components/ui'
import ProductImage from '@components/product/ProductImage'
import { DiscoveryProductSearchResult } from '@commerce/types/product'

const AUTOCOMPLETE_DEBOUNCE_TIME = 200
const AUTOCOMPLETE_RESULT_LIMIT = 10

const Searchbar: FC<React.PropsWithChildren<unknown>> = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement>(null)
  const autocompleteRef = useRef() as React.MutableRefObject<HTMLDivElement>
  const [inputValue, setInputValue] = useState('')
  const [autocompleteData, setAutocompleteData] =
    useState<DiscoveryQueryData | null>()
  const { q } = router.query
  const {
    searchBarShowing,
    searchAutocompleteShowing,
    setSearchAutocompleteShowing,
  } = useNavbarContext()

  useEffect(() => {
    router.prefetch('/search')
  }, [router])

  useEffect(() => {
    if (!q) {
      setInputValue('')
    } else {
      const newValue = Array.isArray(q) ? q[0] : q
      setInputValue(newValue)
    }
  }, [q])

  useEffect(() => {
    const updateBodyScroll = (body: HTMLDivElement) => {
      if (searchAutocompleteShowing) {
        disableBodyScroll(body, { reserveScrollBarGap: true })
      } else {
        enableBodyScroll(body)
        clearAllBodyScrollLocks()
      }
    }

    if (autocompleteRef.current) {
      updateBodyScroll(autocompleteRef.current)
    }
  }, [searchAutocompleteShowing])

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (e.key === 'Enter' && !!inputValue) {
      handleSearch()
    }
    if (e.key === 'Escape') {
      closeAutocomplete(false)
    }
  }

  const handleSearch = () => {
    router.push({
      pathname: `/search`,
      query: inputValue ? { q: inputValue.replace(':', '') } : {},
    })
    closeAutocomplete(true)
  }

  const debouncedFetch = useMemo(
    () =>
      debounce(async (value) => {
        const { data: results } = await fetchDiscoveryAutocomplete(
          value.replace(':', ''),
          AUTOCOMPLETE_RESULT_LIMIT,
        )
        if (results && results?.hits) {
          setAutocompleteData(results)
          if (results.hits && window.analytics) {
            window.analytics.track('Autocomplete Showing', {
              query: value,
              hits: results.hits,
            })
          }
        }
      }, AUTOCOMPLETE_DEBOUNCE_TIME),
    [],
  )

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value
    setInputValue(val)
    if (val) {
      setSearchAutocompleteShowing(true)
      // do search
      debouncedFetch(val)
    } else {
      setSearchAutocompleteShowing(false)
    }
  }

  const handleFocus = () => {
    if (inputValue && autocompleteData) {
      setSearchAutocompleteShowing(true)
    }
  }

  const closeAutocomplete = (keepInput?: boolean) => {
    setSearchAutocompleteShowing(false)
    inputRef.current?.blur()
    if (!keepInput) {
      setInputValue('')
      setAutocompleteData(null)
    }
  }

  const handleHitClick = (hit: DiscoveryProductSearchResult) => {
    closeAutocomplete(false)
    if (window.analytics) {
      window.analytics.track('Autocomplete Result Clicked', {
        query: inputValue,
        hit,
      })
    }
  }

  const handleSeeAllClick = () => {
    closeAutocomplete(true)
    if (window.analytics) {
      window.analytics.track('Autocomplete See All Clicked', {
        query: inputValue,
      })
    }
  }

  return (
    <div
      className={cn(s.root, {
        [s.showing]: searchBarShowing,
        [s.autocompleteShowing]: searchAutocompleteShowing,
      })}
    >
      <div className={s.autocomplete} ref={autocompleteRef}>
        <Container>
          {autocompleteData?.hits && autocompleteData.hits.length > 0 && (
            <div className={s.autocompleteResults}>
              <div className={s.head}>
                {t('results')} ({autocompleteData.found})
              </div>
              {autocompleteData.hits.map((hit) => (
                <Link
                  key={hit.sku}
                  href={hit.slug ? `/products/${hit.sku}/${hit.slug}` : ''}
                >
                  <a onClick={() => handleHitClick(hit)} className={s.hit}>
                    <span className={s.cover}>
                      <ProductImage
                        imageUrl={hit.cover}
                        productName={hit.title}
                        size="sm"
                      />
                    </span>
                    <span>
                      <span className={s.title}>{hit.title}</span>
                      <span className={s.meta}>
                        {t(hit.productType)} · {hit.authors.join(', ')}
                      </span>
                    </span>
                  </a>
                </Link>
              ))}
              <Link href={`/search?q=${inputValue}`}>
                <a onClick={handleSeeAllClick} className={s.seeAll}>
                  {t('see_all')} {autocompleteData.found} "{inputValue}"{' '}
                  {t('results')}
                </a>
              </Link>
            </div>
          )}
        </Container>
        <button
          onClick={() => closeAutocomplete(false)}
          className={s.autocompleteCloseBtn}
        >
          <Cross />
        </button>
      </div>
      <div className={s.input}>
        <input
          value={inputValue}
          placeholder={t('search.placeholder_type') || ''}
          onKeyUp={handleKeyUp}
          ref={inputRef}
          autoComplete="off"
          onChange={handleChange}
          onFocus={handleFocus}
        />
        <SearchIcon className={s.searchIcon} />
      </div>
    </div>
  )
}

export default Searchbar

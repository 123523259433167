import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import s from './CountryPickerTrigger.module.css'
import Flag from '@components/icons/Flag'
import { useCountryPickerModal } from '@lib/country-picker'
import { getLanguageObjectFromLocale, useLocale } from '@lib/locales/utils'

const CountryPickerTrigger: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()
  const { openCountryPickerModal } = useCountryPickerModal()
  const { locale: currentLocale } = useLocale()
  const currentRegion = getLanguageObjectFromLocale(currentLocale)

  const handleCountryPickerClick = () => {
    openCountryPickerModal()
    if (window.analytics) {
      window.analytics.track('Menu Item Clicked', {
        label: t('navigation.change_country'),
      })
    }
  }

  return (
    <button
      aria-label="Pick region"
      className={s.countryPickerBtn}
      onClick={handleCountryPickerClick}
    >
      <span>
        <Flag
          locale={currentLocale}
          width="24"
          height="24"
          className={s.flag}
        />
      </span>
      <span>
        {currentRegion.countryName}{' '}
        <span className={s.cta}>{t('navigation.change_country')}</span>
      </span>
    </button>
  )
}

export default CountryPickerTrigger

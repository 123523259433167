import { FC, useState } from 'react'
import s from './CartView.module.css'
import CartItem from '../CartItem'
import { Button, Headline } from '@components/ui'
import { useCart } from '@contexts/CartContext'
import { formatPrice } from '@commerce/product/use-price'
import { useTranslation } from 'next-i18next'
import { LineItem } from '@commerce/types/cart'
import { SegmentProduct } from '@commerce/types/product'
import { useAuth0 } from '@auth0/auth0-react'
import goToCheckout from '@lib/checkout/go-to-checkout'
import { useLocale } from '@lib/locales/utils'

const CartView: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()
  const { user } = useAuth0()
  const { data, isEmpty } = useCart()
  const { lineItems, discountCode } = data
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false)
  const { locale, currencyCode } = useLocale()
  const [checkoutError, setCheckoutError] = useState('')

  const total = formatPrice({
    amount: Number(data.totalPrice),
    currencyCode,
    locale,
  })

  const onGoToCheckout = async () => {
    setCheckoutError('')
    setIsLoadingCheckout(true)
    try {
      const checkoutId = await goToCheckout({ locale, lineItems, discountCode })
      if (data && window.analytics) {
        const segmentLineItems: SegmentProduct[] = []
        lineItems.forEach((item) => {
          const attributes = item.customAttributes
          if (attributes?.isbn && attributes?.sesamyId) {
            segmentLineItems.push({
              product_id: `isbn:${attributes.isbn}`, // eslint-disable-line camelcase
              sesamy_id: attributes.sesamyId, // eslint-disable-line camelcase
              name: item.name,
              brand: item.vendor,
              price: Number(item.variant.price),
              currency: data?.currency.code,
              quantity: 1,
              url: `https://store.sesamy.com/products/${item.id}/${item.path}`,
              image_url: item.variant.image?.url, // eslint-disable-line camelcase
            })
          }
        })
        window.analytics.track('Checkout Started', {
          order_id: checkoutId, // eslint-disable-line camelcase
          value: data.subtotalPrice,
          revenue: data.subtotalPrice,
          currency: data.currency.code,
          products: segmentLineItems,
        })
        if (!user) {
          window.analytics.track('Checkout Step Viewed', {
            checkout_id: checkoutId, // eslint-disable-line camelcase
            step: 1,
          })
        }
      }

      const checkoutDomain =
        process.env.NEXT_PUBLIC_CHECKOUT_2_FEATURE_FLAG === 'true'
          ? process.env.NEXT_PUBLIC_CHECKOUT_2_URL
          : process.env.NEXT_PUBLIC_CHECKOUT_URL

      console.log('checkoutDomain', checkoutDomain)

      // this is the URL we redirect to when the checkout button is hit
      const checkoutUrl = `${checkoutDomain}/${checkoutId}?lang=${locale}`

      window.location.assign(checkoutUrl)
    } catch (e) {
      // Ooops! something else should happen here - is this enough for datadog?
      console.error(e)
      // should also
      setCheckoutError(t('default_error_message') || '')
      /* Errors to handle
       * bad checkout Id - hardcode this and test
       * bad products? - edit local storage manually to break
       * network errors? can simulate?
       */
    } finally {
      setIsLoadingCheckout(false)
    }
  }

  return (
    <div data-testid="cart-view">
      {isEmpty ? (
        <div className="justify-top flex flex-1 flex-col items-start">
          <Headline Component="h2" variant="subheading1" className="pt-4 pb-6">
            {t('cart_sidebar.general.title')}
          </Headline>
          {isEmpty && (
            <h2 className="text-center tracking-wide">
              {t('cart_sidebar.general.cart_empty')}
            </h2>
          )}
        </div>
      ) : (
        <>
          <div className="justify-top flex flex-col items-start ">
            <Headline
              Component="h2"
              variant="subheading1"
              className="pt-4 pb-6"
            >
              {t('cart_sidebar.general.title')}
            </Headline>
            <ul className={s.lineItemsList}>
              {data!.lineItems.map((item: LineItem) => (
                <CartItem
                  key={item.id}
                  item={item}
                  currencyCode={data!.currency.code}
                />
              ))}
            </ul>
          </div>

          <div className="right-0 left-0 flex items-center justify-center overflow-hidden py-6">
            <div className={s.totalPriceContainer}>
              <strong>
                {t('cart_sidebar.label.total')}: {total}
              </strong>
            </div>
            <div className={s.checkoutButtonContainer}>
              <Button
                Component="a"
                onClick={onGoToCheckout}
                className="w-full"
                loading={isLoadingCheckout}
              >
                {t('cart_sidebar.general.checkout')}
              </Button>
            </div>
          </div>
          {/* TODO - do we want the same styling everywhere? or ask Ferran for some ideas? or just leave bold like this */}
          {!!checkoutError && <p className="font-bold">{checkoutError}</p>}
        </>
      )}
    </div>
  )
}

export default CartView

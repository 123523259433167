import { FC } from 'react'
import { useCart } from '@contexts/CartContext'
import { useNavbarContext } from '@contexts/NavBarContext'
import { useTranslation } from 'next-i18next'
import { LineItem } from '@commerce/types/cart'
import CartIcon from '@components/icons/CartIcon'
import s from './CartButton.module.css'

const countItem = (count: number, item: LineItem) => count + item.quantity

const CartButton: FC<React.PropsWithChildren<unknown>> = () => {
  const { data } = useCart()
  const itemsCount = data?.lineItems.reduce(countItem, 0) ?? 0
  const { setCartOpen } = useNavbarContext()
  const { t } = useTranslation()

  const handleCartButtonClick = () => {
    setCartOpen(true)
    if (window.analytics) {
      window.analytics.track('Header Cart Button Clicked', {
        cart_count: itemsCount, // eslint-disable-line camelcase
      })
    }
  }

  return (
    <div>
      <button
        type="button"
        aria-label="Cart"
        className={s.button}
        onClick={handleCartButtonClick}
      >
        <span className={s.label}>{t('cart')}</span>
        <CartIcon />
        {itemsCount > 0 && <span className={s.count}>{itemsCount}</span>}
      </button>
    </div>
  )
}

export default CartButton

import cn from 'classnames'
import React, {
  forwardRef,
  ButtonHTMLAttributes,
  JSXElementConstructor,
  useRef,
} from 'react'
import mergeRefs from 'react-merge-refs'
import s from './Button.module.css'
import Spinner from '@components/icons/Spinner'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  variant?: 'primary' | 'secondary' | 'tertiary'
  size?: 'large' | 'small' | 'largeOnDesktop'
  align?: 'center' | 'left' | 'right'
  Component?: string | JSXElementConstructor<any>
  loading?: boolean
  disabled?: boolean
  icon?: JSX.Element
  iconPosition?: 'left' | 'right'
  href?: string
  target?: string
  rel?: string
}

const Button: React.FC<React.PropsWithChildren<ButtonProps>> = forwardRef(
  (props, buttonRef) => {
    const {
      children,
      className = '',
      variant = 'primary',
      size = 'large',
      align = 'center',
      Component = 'button',
      loading = false,
      disabled = false,
      icon,
      iconPosition = 'right',
      ...rest
    } = props
    const ref = useRef<typeof Component>(null)

    const rootClassName = cn(s.root, className, {
      [s.primary]: variant === 'primary',
      [s.secondary]: variant === 'secondary',
      [s.tertiary]: variant === 'tertiary',
      [s.small]: size === 'small',
      [s.largeOnDesktop]: size === 'largeOnDesktop',
      [s.loading]: loading,
      [s.disabled]: disabled,
      ['justify-center text-center']: align === 'center',
      ['justify-start text-start']: align === 'left',
      ['justify-end text-end']: align === 'right',
    })

    return (
      <Component
        data-variant={variant}
        ref={mergeRefs([ref, buttonRef])}
        className={rootClassName}
        disabled={disabled}
        {...rest}
      >
        {iconPosition === 'left' && icon}
        <span>{children}</span>
        {iconPosition === 'right' && icon}
        {loading && (
          <span className={cn(s.spinner, 'animate-spin')}>
            <Spinner height="20" width="20" />
          </span>
        )}
      </Component>
    )
  },
)

Button.displayName = 'Button'
export default Button

import { useNavbarContext } from '@contexts/NavBarContext'
import { FC } from 'react'
import s from './SearchButton.module.css'
import { SearchIcon } from '@components/icons'
import cn from 'classnames'

const SearchButton: FC<React.PropsWithChildren<unknown>> = () => {
  const { searchBarShowing, setSearchBarShowing } = useNavbarContext()

  const handleSearchBtnClick = () => {
    setSearchBarShowing(!searchBarShowing)
    if (window.analytics) {
      const eventName = searchBarShowing ? 'Close Searchbar' : 'Open Searchbar'
      window.analytics.track(eventName)
    }
  }

  return (
    <button
      type="button"
      aria-label="Search"
      className={cn(s.root, { [s.active]: searchBarShowing })}
      onClick={handleSearchBtnClick}
    >
      <SearchIcon />
    </button>
  )
}

export default SearchButton

import cn from 'classnames'
import s from './FeatureBar.module.css'
import { LayoutProps } from '@commerce/types/layout'

interface FeatureBarProps {
  className?: string
  title: React.ReactNode
  description?: string
  hide?: boolean
  action?: React.ReactNode
  layout: LayoutProps
}

const FeatureBar: React.FC<React.PropsWithChildren<FeatureBarProps>> = ({
  title,
  description,
  className,
  action,
  hide,
  layout,
}) => {
  const rootClassName = cn(
    s.root,
    {
      transform: true,
      'translate-y-0 opacity-100': !hide,
      'translate-y-full opacity-0': hide,
    },
    {
      [s.pageCMS]: layout.pageType === 'CMS',
      [s.pageCampaign]: layout.pageType === 'CampaignPage',
      [s.pageAbout]: layout.pageType === 'AboutPage',
    },
    className,
  )
  if (hide) return null
  return (
    <div className={rootClassName}>
      <span className="block md:inline">{title}</span>
      <span className="mb-6 block md:mb-0 md:ml-2 md:inline">
        {description}
      </span>
      {action && action}
    </div>
  )
}

export default FeatureBar

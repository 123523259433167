import { useMemo } from 'react'
import {
  useLocale,
  getLanguageObjectFromLocale,
  localeType,
} from '@lib/locales/utils'
import type {
  DiscoveryProductProduct,
  DiscoveryProductSearchResult,
} from '@commerce/types/product'

// TODO - work out what can delete from here...
// Then move out into utils folder

const currencies: {
  [key: string]: {
    rate: number
    vatAddOn: number
  }
} = {
  SEK: {
    rate: 1,
    vatAddOn: 1,
  },
  DKK: {
    rate: 0.735,
    vatAddOn: 1.19,
  },
}

export function convertCurrency({
  amount,
  currencyCode,
}: {
  amount: number
  currencyCode: string
}) {
  return Math.ceil(
    amount * currencies[currencyCode].rate * currencies[currencyCode].vatAddOn,
  )
}

export function formatPrice({
  amount,
  currencyCode,
  locale,
}: {
  amount: number
  // TODO - use types coming from utils! they're exported
  currencyCode: string
  // TODO - can use locale type?
  locale: string
}) {
  const formatCurrency = new Intl.NumberFormat(
    // 'en' locale results in formats like "100 SEK"
    locale === 'en' ? 'sv' : locale,
    {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  )

  return formatCurrency.format(amount)
}

export function formatVariantPrice({
  amount,
  baseAmount,
  currencyCode,
  locale,
}: {
  baseAmount: number
  amount: number
  currencyCode: string
  // TODO - can use locale type?
  locale: string
}) {
  const hasDiscount = baseAmount > amount
  const formatDiscount = new Intl.NumberFormat(locale, { style: 'percent' })
  const discount = hasDiscount
    ? formatDiscount.format((baseAmount - amount) / baseAmount)
    : null

  const price = formatPrice({ amount, currencyCode, locale })
  const basePrice = hasDiscount
    ? formatPrice({ amount: baseAmount, currencyCode, locale })
    : null

  return { price, basePrice, discount }
}
// Changing implementation this to just be a helper around the object. Could even go OOP and have the object have functions.

export function getPriceAmountDiscovery(
  product: DiscoveryProductProduct | DiscoveryProductSearchResult,
  locale: localeType,
) {
  const { currencyCode: currentCurrency } = getLanguageObjectFromLocale(locale)

  const value = ['en', 'sv'].includes(locale)
    ? product.prices.SE
    : product.prices.DK

  return {
    value: value / 100,
    currencyCode: currentCurrency,
  }
}

// TODO - we should move off this hook. Has linting errors
export default function usePrice(
  data?: {
    amount: number
    baseAmount?: number
    currencyCode: string
  } | null,
) {
  let { amount, baseAmount, currencyCode } = data ?? {}
  const { locale, currencyCode: currentCurrency } = useLocale()

  const value = useMemo(() => {
    if (typeof amount !== 'number' || !currencyCode) return ''

    if (currencyCode !== currentCurrency) {
      amount = convertCurrency({ amount, currencyCode: currentCurrency })
      if (typeof baseAmount === 'number') {
        baseAmount = convertCurrency({
          amount: baseAmount,
          currencyCode: currentCurrency,
        })
      }
    }

    return baseAmount
      ? formatVariantPrice({
          amount,
          baseAmount,
          currencyCode: currentCurrency,
          locale,
        })
      : formatPrice({
          amount,
          currencyCode: currentCurrency,
          locale,
        })
  }, [amount, baseAmount, currentCurrency])

  return typeof value === 'string' ? { price: value } : value
}

const TikTokFlatIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.902"
      height="13.733"
      viewBox="0 0 11.902 13.733"
      {...props}
    >
      <title>TikTok</title>
      <path
        d="M58.379,18.937a3.126,3.126,0,0,1-.27-.157,3.8,3.8,0,0,1-.694-.59,3.275,3.275,0,0,1-.782-1.614h0A1.989,1.989,0,0,1,56.6,16H54.245v9.12c0,.122,0,.243-.005.363,0,.015,0,.029,0,.045a.1.1,0,0,1,0,.02v.005a2,2,0,0,1-1.008,1.589,1.968,1.968,0,0,1-.976.257,2,2,0,0,1,0-4.005,1.971,1.971,0,0,1,.613.1l0-2.4a4.381,4.381,0,0,0-3.376.988,4.629,4.629,0,0,0-1.01,1.245,4.315,4.315,0,0,0-.521,1.981,4.675,4.675,0,0,0,.253,1.566v.006a4.609,4.609,0,0,0,.64,1.168,4.793,4.793,0,0,0,1.022.964V29l.006.006a4.413,4.413,0,0,0,2.408.725,4.262,4.262,0,0,0,1.787-.4,4.484,4.484,0,0,0,1.451-1.091,4.533,4.533,0,0,0,.791-1.314,4.922,4.922,0,0,0,.285-1.5V20.591c.029.017.41.269.41.269a5.455,5.455,0,0,0,1.406.581,8.094,8.094,0,0,0,1.442.2V19.3A3.058,3.058,0,0,1,58.379,18.937Z"
        transform="translate(-47.959 -16)"
      />
    </svg>
  )
}

export default TikTokFlatIcon

import React, { FC } from 'react'
import s from './Quantity.module.css'
import { Cross } from '@components/icons'
export interface QuantityProps {
  handleRemove: React.MouseEventHandler<HTMLButtonElement>
}

const Quantity: FC<React.PropsWithChildren<QuantityProps>> = ({
  handleRemove,
}) => {
  return (
    <div className="flex h-9 flex-row">
      <button className={s.actions} onClick={handleRemove}>
        <Cross width={30} height={30} />
      </button>
    </div>
  )
}

export default Quantity

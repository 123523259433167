import { flattenTopLevelTagsAndNestChildren } from '@lib/themaTags/utils'
import { useLocale } from '@lib/locales/utils'
import { languageType } from '@lib/locales/utils'
import { useEffect, useState } from 'react'
import { ThemaTag } from '@commerce/types/thema-tag'

const getThemaTags = async (language: languageType) => {
  if (language === 'swe') {
    const themaTagsSv = (await import('@lib/themaTags/data/sv')).default
    return themaTagsSv
  }

  if (language === 'dan') {
    const themaTagsDa = (await import('@lib/themaTags/data/sv')).default
    return themaTagsDa
  }

  const themaTagsEn = (await import('@lib/themaTags/data/en')).default
  return themaTagsEn
}

const useThemaTags = () => {
  const { language } = useLocale()
  const [tags, setTags] = useState<ThemaTag[] | undefined>()

  useEffect(() => {
    async function loadThemaTags(language: languageType) {
      const tagsResponse = await getThemaTags(language)
      const tagsNew = flattenTopLevelTagsAndNestChildren(tagsResponse)
      setTags(tagsNew)
    }

    loadThemaTags(language)
  }, [language])

  return tags
}

export default useThemaTags

import React, { FC } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import Link from 'next/link'

import { SesamyFullLogoIcon } from '@components/icons'
import { Container } from '@components/ui'
import PaymentIconList from '../PaymentIconList/PaymentIconList'
import FooterLinksBlock from './FooterLinksBlock'
import FooterTextBlock from './FooterTextBlock'
import FooterSocialMediaLinks from './FooterSocialMediaLinks'
import CountryPickerTrigger from '../CountryPickerTrigger/CountryPickerTrigger'

const ALLOWED_BLOG_LOCALES = ['sv', 'da']

const Footer: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()
  const { locale } = useRouter()
  const commonAboutUsLinks = [
    {
      text: t('navigation.about_sesamy'),
      href: '/pages/about-sesamy',
    },
  ]

  return (
    <footer
      className="w-full bg-gray-800 py-5.5 md:py-10.5 lg:py-14.5"
      data-testid="footer"
    >
      <Container className="flex flex-col space-y-8 md:grid md:auto-rows-auto md:grid-cols-12 md:gap-x-4 md:gap-y-11 md:space-y-0">
        <div className="flex flex-col space-y-8 md:col-span-7 md:row-start-1 md:space-y-11">
          <FooterTextBlock
            title={
              <Link href="/?no-redirect=true">
                <a>
                  <SesamyFullLogoIcon className="mb-1" />
                </a>
              </Link>
            }
            content={
              <div>
                <p className="text-base text-gray-300">
                  <strong>Stockholm, Sweden</strong>
                </p>
                <p className="text-base text-gray-300">
                  Eriksbergsgatan 8B. 114 30 Stockholms län
                </p>
                <p className="text-base text-gray-300">
                  Org nr: 559241-5227 VAT: SE559241522701
                </p>
              </div>
            }
          />
          <FooterTextBlock
            title={t('footer.change_country_title')}
            content={<CountryPickerTrigger />}
          />
          <FooterTextBlock
            title={t('footer.payments_section_title')}
            content={<PaymentIconList />}
          />
          {/* TODO: add language switch*/}
        </div>
        <div className="md:col-span-3 md:row-start-1">
          <FooterLinksBlock
            title={t('footer.about_us_section_title')}
            links={
              // It shows the blog link only in certain countries
              locale && ALLOWED_BLOG_LOCALES.includes(locale)
                ? [
                    ...commonAboutUsLinks,
                    {
                      text: t('navigation.blog'),
                      href: '/blog',
                    },
                  ]
                : commonAboutUsLinks
            }
          />
        </div>
        <div className="md:col-span-2 md:row-start-1">
          <FooterLinksBlock
            title={t('footer.customer_section_title')}
            links={[
              {
                text: t('navigation.help'),
                href: `https://support.sesamy.com/${locale}`,
              },
              {
                text: t('navigation.privacy_policy'),
                href: 'https://sesamy.com/privacy-policy',
              },
              {
                text: t('navigation.terms_of_service'),
                href: 'https://sesamy.com/terms-and-conditions',
              },
              {
                text: t('navigation.refund_policy'),
                href: '/pages/refund-policy',
              },
            ]}
          />
        </div>
        <div className="flex flex-col items-start space-y-8 md:col-span-12 md:row-start-2 md:flex-row md:items-end md:justify-between md:space-y-0">
          <div className="flex w-full flex-col justify-between space-y-8 md:flex-row md:space-y-0 md:space-x-8">
            <FooterSocialMediaLinks />

            <p className="text-sm text-gray-400 md:text-base">
              {t('footer.copyright')}
            </p>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer

import React, { FC } from 'react'
import { useTranslation } from 'next-i18next'

import {
  FacebookFlat,
  LinkedInFlatIcon,
  TikTokFlatIcon,
  InstagramFlatIcon,
} from '@components/icons'

const MEDIA_ICON_CLASSNAMES =
  'fill-current text-white/50 duration-300 ease-in-out hover:text-white'
const LINK_COMMON_PROPS = { target: '_blank', rel: 'noreferrer' }

const FooterSocialMediaLinks: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-row items-center space-x-8">
      <a href={t('footer.linkedin_link') || ''} {...LINK_COMMON_PROPS}>
        <LinkedInFlatIcon className={MEDIA_ICON_CLASSNAMES} />
      </a>
      <a href={t('footer.facebook_link') || ''} {...LINK_COMMON_PROPS}>
        <FacebookFlat className={MEDIA_ICON_CLASSNAMES} />
      </a>
      <a href={t('footer.instagram_link') || ''} {...LINK_COMMON_PROPS}>
        <InstagramFlatIcon className={MEDIA_ICON_CLASSNAMES} />
      </a>
      <a href={t('footer.tiktok_link') || ''} {...LINK_COMMON_PROPS}>
        <TikTokFlatIcon className={MEDIA_ICON_CLASSNAMES} />
      </a>
    </div>
  )
}

export default FooterSocialMediaLinks

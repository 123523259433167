import { useEffect, useState } from 'react'
import cn from 'classnames'
import Link from 'next/link'
import s from './CartItem.module.css'
import type { LineItem } from '@commerce/types/cart'
import usePrice from '@commerce/product/use-price'
import Quantity from '@components/ui/Quantity'
import { useTranslation } from 'next-i18next'
import EbookIcon from '@components/icons/EbookIcon'
import AudiobookIcon from '@components/icons/AudiobookIcon'
import { useCart } from '@contexts/CartContext'
import { useNavbarContext } from '@contexts/NavBarContext'
import ProductImage from '@components/product/ProductImage'

const CartItem = ({
  item,
  variant = 'default',
  currencyCode,
  ...rest
}: {
  variant?: 'default' | 'display'
  item: LineItem
  currencyCode: string
}) => {
  const { t } = useTranslation()
  const [removing, setRemoving] = useState(false)
  const [quantity, setQuantity] = useState<number>(item.quantity)
  const { data, removeItem } = useCart()
  const { setCartOpen } = useNavbarContext()

  const { price } = usePrice({
    amount: item.variant.price * item.quantity,
    baseAmount: item.variant.listPrice * item.quantity,
    currencyCode,
  })

  const handleRemove = async () => {
    setRemoving(true)
    try {
      await removeItem(item)
      if (window.analytics) {
        window.analytics.track('Product Removed', {
          product_id: `isbn:${item.customAttributes?.isbn}`, // eslint-disable-line camelcase
          sesamy_id: item.customAttributes?.sesamyId, // eslint-disable-line camelcase
          name: item.name,
          brand: item.vendor,
          price: Number(item.variant.price),
          currency: data?.currency.code,
          quantity: 1,
          url: `https://store.sesamy.com/products/${item.path}`,
          image_url: item.variant.image?.url, // eslint-disable-line camelcase
        })
      }
    } catch (error) {
      setRemoving(false)
    }
  }

  useEffect(() => {
    // Reset the quantity state if the item quantity changes
    if (item.quantity !== Number(quantity)) {
      setQuantity(item.quantity)
    }
    // TODO: currently not including quantity in deps is intended, but we should
    // do this differently as it could break easily
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.quantity])

  return (
    <li
      className={cn(s.root, {
        'pointer-events-none opacity-50': removing,
      })}
      {...rest}
    >
      <div className="flex flex-row items-center space-x-4">
        <div className="relative z-0 w-20 cursor-pointer overflow-hidden">
          <Link href={`/products/${item.id}/${item.path}`}>
            <a onClick={() => setCartOpen(false)}>
              <ProductImage
                imageUrl={item.variant.image!.url}
                productName={item.name}
                size="sm"
              />
            </a>
          </Link>
        </div>
        <div className="flex-1 text-gray-700">
          <Link href={`/products/${item.id}/${item.path}`}>
            <a className="-mt-1 block">
              <span
                className={s.productName}
                onClick={() => setCartOpen(false)}
              >
                <strong>{item.name}</strong>
              </span>
            </a>
          </Link>
          <div>
            <span>{price}</span>
          </div>
          <div className="flex items-center justify-between">
            {item.productType === 'audiobook' && (
              <div className="flex items-center space-x-2">
                <AudiobookIcon />
                <span>{t('audiobook')}</span>
              </div>
            )}
            {item.productType === 'ebook' && (
              <div className="flex items-center space-x-2">
                <EbookIcon />
                <span>{t('ebook')}</span>
              </div>
            )}
            {variant === 'default' && <Quantity handleRemove={handleRemove} />}
          </div>
        </div>
      </div>
    </li>
  )
}

export default CartItem

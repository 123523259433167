import { FC, ReactNode } from 'react'
import Link from 'next/link'
import { ThemaTag } from '@commerce/types/thema-tag'
import s from './CategoryItem.module.css'
import cn from 'classnames'
import { useLocale } from '@lib/locales/utils'
import { useNavbarContext } from '@contexts/NavBarContext'

const CategoryItem: FC<
  React.PropsWithChildren<{
    themaTag: ThemaTag
    activeCategory?: string | undefined
    level: number
    children?: ReactNode
    itemIsOpen: boolean
    onItemOpen: () => void
    onItemClose: () => void
  }>
> = ({
  themaTag,
  activeCategory,
  level,
  children,
  itemIsOpen,
  onItemOpen,
  onItemClose,
}) => {
  const { language } = useLocale()
  const { setCategoryTreeOpen } = useNavbarContext()

  return (
    <li className={cn(s.listItem, { [s.open]: itemIsOpen })}>
      {children && (
        <span
          className={s.plus}
          onClick={itemIsOpen ? onItemClose : onItemOpen}
          role="button"
          aria-label="Expand category"
        ></span>
      )}
      <Link
        href={`/category/${themaTag.url}?meta.sesamy.language=${language}`}
        prefetch={false}
      >
        <a
          onClick={() => {
            onItemOpen()
            setCategoryTreeOpen(false)
          }}
          className={activeCategory === themaTag.url ? s.current : ''}
        >
          <span className="text-lg">{themaTag.name}</span>
        </a>
      </Link>
      {itemIsOpen && children && (
        <ul className={level === 2 ? s.categoryTreeL2 : s.categoryTreeL3}>
          {children}
        </ul>
      )}
    </li>
  )
}

export default CategoryItem

import { ThemaTag, ThemaTagFromAPI } from '@commerce/types/thema-tag'
import flatten from 'lodash.flatten'
import fromPairs from 'lodash.frompairs'

type Body = { [key: string]: ThemaTagFromAPI }

function removeNulls(value: ThemaTag | null): value is ThemaTag {
  return value != null
}

function mapChildren(themaTag: ThemaTagFromAPI, depth: number): ThemaTag {
  const ALLOWED_KEYS = ['count', 'name', 'url', 'id']
  // This maps all the thema tag keys A...Z
  const childrenThemaTags = Object.keys(themaTag)
    .map((key) => {
      if (ALLOWED_KEYS.includes(key)) return null
      const themaTagId = `${themaTag.id}${key}`
      const tag: ThemaTag = { ...themaTag[key], id: themaTagId }
      const flattenTag = mapChildren(tag, depth + 1)
      return flattenTag
    })
    .filter(removeNulls)

  const returnTag: ThemaTag = {
    name: themaTag.name,
    count: themaTag.count,
    url: themaTag.url,
    id: themaTag.id,
  }

  if (childrenThemaTags.length > 0) {
    returnTag.children = childrenThemaTags
  }

  return returnTag
}

const flattenTopLevelTags = (body: Body): ThemaTagFromAPI[] => {
  return Object.keys(body).map((key) => {
    const tag = body[key]
    return { ...tag, id: key }
  })
}

const flattenTopLevelTagsAndNestChildren = (body: Body) => {
  return flattenTopLevelTags(body).map((themaTag) => mapChildren(themaTag, 1))
}

const getSelectedTags = (themaTags: ThemaTag[], category: string) => {
  for (let i = 0; i < themaTags.length; i++) {
    const tagFirstLevel: ThemaTag = themaTags[i]

    if (tagFirstLevel.url === category) {
      return [tagFirstLevel.url]
    }

    const firstLevelChildren = tagFirstLevel.children || []

    for (let j = 0; j < firstLevelChildren.length; j++) {
      const tagSecondLevel: ThemaTag = firstLevelChildren[j]

      if (tagSecondLevel.url === category) {
        return [tagFirstLevel.url, tagSecondLevel.url]
      }

      const secondLevelChildren = tagSecondLevel.children || []

      for (let k = 0; k < secondLevelChildren.length; k++) {
        const tagThirdLevel: ThemaTag = secondLevelChildren[k]

        if (tagThirdLevel.url === category) {
          return [tagFirstLevel.url, tagSecondLevel.url, tagThirdLevel.url]
        }
      }
    }
  }
  return []
}

const getTag = (themaTag: ThemaTag) => {
  const { children, ...rest } = themaTag

  return {
    children,
    tag: rest,
  }
}

const flattenChildren = (tag: ThemaTag): ThemaTag[] => {
  if (!tag.children || tag.children.length === 0) {
    // recursive base case
    return [tag]
  }

  const { children, tag: tagWithoutChildren } = getTag(tag)

  // For TypeScript to not complain below. Think need two types. ThemaTag with and without children
  if (!children) {
    return [tag]
  }

  return flatten([tagWithoutChildren, ...children.map(flattenChildren)])
}

// Flattens tags to one dimensional List (getList may be better name)
const flattenThemaTags = (themaTags: ThemaTag[]) => {
  return flatten(themaTags.map(flattenChildren))
}

const getListOfUrls = (themaTags: ThemaTag[]) => {
  const flatArray = flattenThemaTags(themaTags)

  return flatArray.map(({ url }) => url)
}

const getMapOfUrlsToNames = (themaTags: ThemaTag[]) => {
  const flatArray = flattenThemaTags(themaTags)

  return fromPairs(flatArray.map(({ url, name }) => [url, name]))
}

const getMapOfNamesToURLs = (themaTags: ThemaTag[]) => {
  const flatArray = flattenThemaTags(themaTags)

  return fromPairs(flatArray.map(({ url, name }) => [name, url]))
}

const getMapOfIdsToThemaTags = (themaTags: ThemaTag[]) => {
  const flatArray = flattenThemaTags(themaTags)

  return fromPairs(flatArray.map((tag) => [tag.id, tag]))
}

const getMapOfURLsToThemaTags = (themaTags: ThemaTag[]) => {
  const flatArray = flattenThemaTags(themaTags)

  return fromPairs(flatArray.map((tag) => [tag.url, tag]))
}

export {
  getSelectedTags,
  mapChildren,
  flattenThemaTags,
  getListOfUrls,
  getMapOfUrlsToNames,
  flattenTopLevelTags,
  flattenTopLevelTagsAndNestChildren,
  getMapOfNamesToURLs,
  getMapOfIdsToThemaTags,
  getMapOfURLsToThemaTags,
}

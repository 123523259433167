import { useRouter } from 'next/router'
import { FC, createContext, useState, useContext, useEffect } from 'react'

const DEFAULT_OPEN_PATHS = ['/', '/search']

interface NavBarContextProps {
  mainMenuOpen: boolean
  setMainMenuOpen: (value: boolean) => void
  categoryTreeOpen: boolean
  setCategoryTreeOpen: (value: boolean) => void
  cartOpen: boolean
  setCartOpen: (value: boolean) => void
  searchBarShowing: boolean
  setSearchBarShowing: (value: boolean) => void
  searchAutocompleteShowing: boolean
  setSearchAutocompleteShowing: (value: boolean) => void
}

export const NavBarContext = createContext<NavBarContextProps>({
  mainMenuOpen: false,
  setMainMenuOpen: (value: boolean) => value,
  categoryTreeOpen: false,
  setCategoryTreeOpen: (value: boolean) => value,
  cartOpen: false,
  setCartOpen: (value: boolean) => value,
  searchBarShowing: false,
  setSearchBarShowing: (value: boolean) => value,
  searchAutocompleteShowing: false,
  setSearchAutocompleteShowing: (value: boolean) => value,
})

export const NavBarProvider: FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const router = useRouter()
  const [mainMenuOpen, setMainMenuOpen] = useState<boolean>(false)
  const [categoryTreeOpen, setCategoryTreeOpen] = useState<boolean>(false)
  const [cartOpen, setCartOpen] = useState<boolean>(false)
  const [searchBarShowing, setSearchBarShowing] = useState<boolean>(
    DEFAULT_OPEN_PATHS.includes(router.pathname),
  )
  const [searchAutocompleteShowing, setSearchAutocompleteShowing] =
    useState<boolean>(false)

  useEffect(() => {
    setSearchBarShowing(DEFAULT_OPEN_PATHS.includes(router.pathname))
  }, [router.pathname, setSearchBarShowing])

  return (
    <NavBarContext.Provider
      value={{
        mainMenuOpen,
        setMainMenuOpen,
        categoryTreeOpen,
        setCategoryTreeOpen,
        cartOpen,
        setCartOpen,
        searchBarShowing,
        setSearchBarShowing,
        searchAutocompleteShowing,
        setSearchAutocompleteShowing,
      }}
    >
      {children}
    </NavBarContext.Provider>
  )
}

export const useNavbarContext = () => {
  return useContext(NavBarContext)
}

import { FC } from 'react'

import {
  VisaCardIcon,
  MastercardCardIcon,
  AmexCardIcon,
  PaypalCardIcon,
  KlarnaCardIcon,
  SwishCardIcon,
} from '@components/icons'

const PaymentIconList: FC<React.PropsWithChildren<unknown>> = () => (
  <div className="flex flex-row space-x-2.5">
    <VisaCardIcon />
    <MastercardCardIcon />
    <AmexCardIcon />
    <PaypalCardIcon />
    <KlarnaCardIcon />
    <SwishCardIcon />
  </div>
)

export default PaymentIconList

const VisaCardIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="27"
      viewBox="0 0 49 27"
      {...props}
    >
      <title>Visa</title>
      <g transform="translate(-963 -1323)">
        <rect
          width="49"
          height="27"
          rx="5"
          transform="translate(963 1323)"
          fill="#fff"
        />
        <g transform="translate(888.889 1226.328)">
          <path
            id="polygon9"
            d="M436.072,120.646h-2.565l1.6-9.919h2.565Z"
            transform="translate(-341.186 -5.499)"
            fill="#00579f"
          />
          <path
            id="path11"
            d="M572.873,105.47a6.322,6.322,0,0,0-2.3-.422c-2.533,0-4.316,1.351-4.327,3.282-.021,1.425,1.277,2.216,2.248,2.691.992.485,1.33.8,1.33,1.235-.01.665-.8.971-1.54.971a5.1,5.1,0,0,1-2.406-.528l-.338-.159-.359,2.227a7.805,7.805,0,0,0,2.861.528c2.691,0,4.443-1.33,4.464-3.388.01-1.129-.675-1.994-2.153-2.7-.9-.454-1.446-.76-1.446-1.224.01-.422.465-.855,1.477-.855a4.358,4.358,0,0,1,1.911.38l.232.105.349-2.142Z"
            transform="translate(-468.689 0)"
            fill="#00579f"
          />
          <path
            id="path13"
            d="M794.49,117.132c.211-.57,1.024-2.775,1.024-2.775-.011.021.211-.58.337-.95l.179.855s.486,2.374.591,2.87Zm3.166-6.405h-1.984a1.266,1.266,0,0,0-1.341.823l-3.81,9.1h2.691l.538-1.488h3.293c.074.348.306,1.488.306,1.488h2.375l-2.069-9.919Z"
            transform="translate(-686.898 -5.499)"
            fill="#00579f"
          />
          <path
            id="path15"
            d="M172.168,110.727l-2.512,6.764-.275-1.372a7.592,7.592,0,0,0-3.546-4.158l2.3,8.675h2.712l4.032-9.909h-2.712Z"
            transform="translate(-81.989 -5.499)"
            fill="#00579f"
          />
          <path
            id="path17"
            d="M85.335,110.727H81.208l-.042.2a8.582,8.582,0,0,1,6.227,5.192l-.9-4.559a1.061,1.061,0,0,0-1.161-.834Z"
            transform="translate(0 -5.499)"
            fill="#faa61a"
          />
        </g>
      </g>
    </svg>
  )
}

export default VisaCardIcon

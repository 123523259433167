const PaypalCardIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="27"
      viewBox="0 0 49 27"
      {...props}
    >
      <title>Paypal</title>
      <g transform="translate(-1139 -1323)">
        <rect
          width="49"
          height="27"
          rx="5"
          transform="translate(1139 1323)"
          fill="#fff"
        />
        <g id="paypal-seeklogo.com" transform="translate(1156.335 1329)">
          <path
            d="M173.627,386.87h4.356c2.339,0,3.219,1.184,3.083,2.924-.225,2.872-1.961,4.461-4.264,4.461H175.64c-.316,0-.529.209-.614.776l-.494,3.295c-.033.214-.145.337-.314.354h-2.737c-.258,0-.349-.2-.281-.623l1.669-10.563A.664.664,0,0,1,173.627,386.87Z"
            transform="translate(-168.063 -383.234)"
            fill="#009ee3"
            fillRule="evenodd"
          />
          <path
            d="M146.32,354.54h4.361a5.45,5.45,0,0,1,3.659.9,2.956,2.956,0,0,1,.914,2.474c-.268,3.33-2.259,5.2-4.931,5.2h-2.15c-.367,0-.608.243-.712.9l-.6,3.823c-.039.247-.146.394-.337.412h-2.691c-.3,0-.4-.225-.326-.722l1.934-12.255A.774.774,0,0,1,146.32,354.54Z"
            transform="translate(-143.48 -354.54)"
            fill="#113984"
            fillRule="evenodd"
          />
          <path
            d="M179.43,392.306l.761-4.821a.666.666,0,0,1,.759-.625h4.356a4.249,4.249,0,0,1,1.761.321c-.437,2.964-2.355,4.611-4.865,4.611h-2.147A.634.634,0,0,0,179.43,392.306Z"
            transform="translate(-175.386 -383.226)"
            fill="#172c70"
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  )
}

export default PaypalCardIcon

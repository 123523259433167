import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'

const COOKIE_NAME = 'accept_cookies'

export const useAcceptCookies = () => {
  // this is set to false initially in this project, but I think might make LCP too high for bots
  // now logged in users will get a flash though... Hmmm. Let's try for a bit
  // const [acceptedCookies, setAcceptedCookies] = useState(true)
  const [acceptedCookies, setAcceptedCookies] = useState(false)

  useEffect(() => {
    // if (!Cookies.get(COOKIE_NAME)) {
    //   setAcceptedCookies(false)
    // }
    if (Cookies.get(COOKIE_NAME)) {
      setAcceptedCookies(true)
    }
  }, [])

  const acceptCookies = () => {
    setAcceptedCookies(true)
    Cookies.set(COOKIE_NAME, 'accepted', { expires: 365 })
  }

  return {
    acceptedCookies,
    onAcceptCookies: acceptCookies,
  }
}

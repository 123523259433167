import { useAuth0 } from '@auth0/auth0-react'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useLocale } from '@lib/locales/utils'
import s from './MainMenuNav.module.css'
import { baseRedirectUri } from '@lib/auth'

const LOGIN_PAGE = '/login'

const MenuLoginButton = ({
  closeMobileMenu,
}: {
  closeMobileMenu: () => void
}) => {
  const { user, loginWithRedirect } = useAuth0()
  const { asPath, pathname } = useRouter()
  const { t } = useTranslation()
  const { localePrefix } = useLocale()

  const returnToUrl = `${baseRedirectUri}${localePrefix}${asPath}`

  const handleMyAccountClick = () => {
    closeMobileMenu()
    if (window.analytics) {
      window.analytics.track('Menu Item Clicked', {
        label: t('navigation.my_account'),
      })
    }
  }
  const handleLoginClick = () => {
    closeMobileMenu()
    if (window.analytics) {
      window.analytics.track('Menu Item Clicked', {
        label: t('navigation.sign_in'),
      })
    }
    loginWithRedirect({
      authorizationParams: {
        redirect_uri: returnToUrl,
      },
    })
  }

  if (user) {
    return (
      <a
        onClick={handleMyAccountClick}
        className={s.link}
        href={process.env.NEXT_PUBLIC_VAULT_URL}
      >
        {t('navigation.my_account')}
      </a>
    )
  }

  if (pathname === LOGIN_PAGE) {
    return (
      <span onClick={closeMobileMenu} className={s.link}>
        {t('navigation.sign_in')}
      </span>
    )
  }

  return (
    <button onClick={handleLoginClick} className={s.link}>
      {t('navigation.sign_in')}
    </button>
  )
}

export default MenuLoginButton

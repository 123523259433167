import Cookies from 'js-cookie'

import {
  SESAMY_LOCALE_MODAL_RENDERED_COOKIE_NAME,
  SESAMY_IP_COUNTRY_COOKIE_NAME,
  SESAMY_USER_LOCALE_COOKIE_NAME,
} from '../constants'
import {
  getLanguageObjectFromCountryCode,
  localeType,
} from '@lib/locales/utils'
import { useModal } from '@contexts/ModalContext'
import CountryPickerModalContent from '@components/common/CountryPickerModal/CountryPickerModalContent'

/**
 * We will show the modal under the next conditions:
 *
 * - Modal has not been showed in the last 24 hours
 * - The ip locale and the Next.js local are different
 * - User did not pick a country before
 */
export const shouldRenderCountryModal = (locale: string) => {
  const ipLocale = getIpLocale()
  const userLocale = Cookies.get(SESAMY_USER_LOCALE_COOKIE_NAME)
  const isRendered = Boolean(
    Cookies.get(SESAMY_LOCALE_MODAL_RENDERED_COOKIE_NAME),
  )
  const isDifferentCountry = ipLocale !== locale

  return !userLocale && !isRendered && isDifferentCountry
}

export const setCountryModalRendered = () => {
  Cookies.set(SESAMY_LOCALE_MODAL_RENDERED_COOKIE_NAME, 'true', {
    // Sesamy locale modal will be shown again the next day unless a user selects one
    expires: 1,
  })
}

/**
 * The cookie responsible to keep track if the country modal was rendered is removed
 * as it is not necessary after user sets a locale
 *
 */
export const setUserLocale = (newLocale: string) => {
  Cookies.set(SESAMY_USER_LOCALE_COOKIE_NAME, newLocale)
  Cookies.remove(SESAMY_LOCALE_MODAL_RENDERED_COOKIE_NAME)
}

export const getUserLocale = (): localeType | null => {
  return (Cookies.get(SESAMY_USER_LOCALE_COOKIE_NAME) as localeType) || null
}

export const getIpLocale = (): localeType | null => {
  const ipCountry = Cookies.get(SESAMY_IP_COUNTRY_COOKIE_NAME)

  if (!ipCountry) {
    return null
  }

  const localeObject = getLanguageObjectFromCountryCode(ipCountry)

  if (!localeObject) {
    return null
  }

  return localeObject.locale
}

export const useCountryPickerModal = () => {
  const { openModal } = useModal()

  function openCountryPickerModal() {
    const hideCloseBtn = !!process.env.NEXT_PUBLIC_SHOW_BUSINESSBANNER
    openModal(<CountryPickerModalContent />, 'small', hideCloseBtn)
  }

  return { openCountryPickerModal }
}

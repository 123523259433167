const FacebookFlat = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7.13"
      height="15.466"
      viewBox="0 0 7.13 15.466"
      fill="#222"
      {...props}
    >
      <title>Facebook</title>
      <path
        d="M44.655,29.481H42.54v7.748h-3.2V29.481H37.812V26.758h1.524V25a3,3,0,0,1,3.233-3.233l2.373.01v2.643H43.22a.652.652,0,0,0-.68.742v1.6h2.395Z"
        transform="translate(-37.812 -21.763)"
      />
    </svg>
  )
}

export default FacebookFlat

const MastercardCardIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="27"
      viewBox="0 0 48 27"
      {...props}
    >
      <title>Mastercard</title>
      <g transform="translate(-1022 -1323)">
        <rect
          width="48"
          height="27"
          rx="5"
          transform="translate(1022 1323)"
          fill="#fff"
        />
        <g transform="translate(1033.826 1329.199)">
          <g>
            <rect
              width="6.459"
              height="11.603"
              transform="translate(8.694 1.579)"
              fill="#ff5a00"
            />
            <path
              d="M9.124,7.381a7.4,7.4,0,0,1,2.809-5.8A7.378,7.378,0,1,0,7.381,14.761a7.341,7.341,0,0,0,4.553-1.579A7.366,7.366,0,0,1,9.124,7.381Z"
              fill="#eb001b"
            />
            <path
              d="M511.533,7.381a7.373,7.373,0,0,1-11.933,5.8,7.4,7.4,0,0,0,0-11.6,7.373,7.373,0,0,1,11.933,5.8Z"
              transform="translate(-487.667)"
              fill="#f79e1b"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default MastercardCardIcon

import { Container, Headline } from '@components/ui'

function NewHomePage() {
  return (
    <div className="bg-[#08080E] text-white min-h-screen flex flex-col justify-center items-center">
      <Container className="text-center">
        <Headline
          Component="h1"
          variant="heading2"
          className="text-[48px] font-semibold mb-4"
        >
          The store is closed for business
        </Headline>
        <p className="text-[#88869F] mb-[24px]">
          You can access the audio and e-books you’ve already purchased for 3
          months until <span className="text-white">01-09-24</span>.
          <br />
          Press Open account to access or download them to your phone or
          computer
        </p>
        <div className="flex justify-center gap-[10px]">
          <a
            className="bg-[#7D68F4] px-[32px] py-[14px] text-white rounded-[8px]"
            href="https://account.sesamy.com"
          >
            Open Account
          </a>
          <a
            className="bg-[#7D68F4] px-[32px] py-[14px] text-white rounded-[8px]"
            href="https://sesamy.com"
          >
            Open Sesamy.com
          </a>
        </div>
      </Container>
    </div>
  )
}

export default NewHomePage

import { LineItem, CreateSesamyCheckout } from '@commerce/types/cart'

type GoToCheckoutParams = {
  locale: string
  lineItems: LineItem[]
  discountCode?: string
}

// https://fettblog.eu/typescript-hasownproperty/
function hasOwnProperty<X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y,
): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop)
}

const goToCheckout = async ({
  lineItems,
  discountCode,
}: GoToCheckoutParams) => {
  // TODO - use SWR? for retries and error handling...
  const data = await fetch(
    `${process.env.NEXT_PUBLIC_WALLET_API}/api/v1/checkout`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        items: lineItems.map((l) => ({ sku: l.id })),
        discountCode,
      }),
    },
  )

  const checkout: CreateSesamyCheckout = await data.json()

  if (hasOwnProperty(checkout, 'error')) {
    throw new Error(
      'Could not create checkout with items: ' +
        lineItems.map((item) => item.id).join(','),
    )
  }

  const checkoutId = checkout.item.id

  return checkoutId
}

export default goToCheckout

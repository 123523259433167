import cn from 'classnames'
import { FC, ReactNode, ComponentType, HTMLAttributes } from 'react'

interface ContainerProps {
  className?: string
  children?: ReactNode
  el?: HTMLElement
  noPadding?: boolean
  maxWidth?: 'narrow' | 'medium' | 'wide'
}

const Container: FC<React.PropsWithChildren<ContainerProps>> = ({
  children,
  className,
  el = 'div',
  noPadding,
  maxWidth = 'wide',
}) => {
  const rootClassName = cn(className, 'mx-auto', {
    'px-5.5 md:px-10.5 lg:px-14.5': !noPadding,
    'max-w-narrow': maxWidth === 'narrow',
    'max-w-medium': maxWidth === 'medium',
    'max-w-wide': maxWidth === 'wide',
  })

  const Component: ComponentType<
    React.PropsWithChildren<HTMLAttributes<HTMLDivElement>>
  > = el as any

  return <Component className={rootClassName}>{children}</Component>
}

export default Container

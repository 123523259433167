import { FC, useEffect, useRef } from 'react'
import s from './Drawer.module.css'
import cn from 'classnames'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import { Cross } from '@components/icons'

interface DrawerProps {
  isOpen: boolean
  onClose: () => void
  from?: 'left' | 'right'
  theme?: 'dark' | 'light'
  blackout?: boolean
}

const Drawer: FC<React.PropsWithChildren<DrawerProps>> = ({
  children,
  isOpen,
  onClose,
  from = 'left',
  theme = 'dark',
  blackout = true,
}) => {
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>

  useEffect(() => {
    const updateBodyScroll = (body: HTMLDivElement) => {
      if (isOpen) {
        disableBodyScroll(body, { reserveScrollBarGap: true })
      } else {
        enableBodyScroll(body)
        clearAllBodyScrollLocks()
      }
    }

    if (ref.current) {
      updateBodyScroll(ref.current)
    }
  }, [isOpen])

  return (
    <div
      className={cn(s.root, {
        [s.isOpen]: isOpen,
        [s.fromLeft]: from === 'left',
        [s.fromRight]: from === 'right',
        [s.themeDark]: theme === 'dark',
        [s.themeLight]: theme === 'light',
        [s.noBlackout]: !blackout,
      })}
    >
      <div className={s.blackout} onClick={onClose}></div>
      <div className={s.drawer}>
        <div className={s.scroll} ref={ref}>
          {children}
        </div>
        <button className={s.closeBtn} aria-label="Close" onClick={onClose}>
          <Cross />
        </button>
      </div>
    </div>
  )
}

export default Drawer

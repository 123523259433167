import UserIcon from '@components/icons/UserIcon'
import { useAuth0 } from '@auth0/auth0-react'
import { useTranslation } from 'next-i18next'
import { FC, useState } from 'react'
import s from './UserButton.module.css'
import { useRouter } from 'next/router'
import { useLocale } from '@lib/locales/utils'
import cn from 'classnames'
import UserMenu from './UserMenu'
import { baseRedirectUri } from '@lib/auth'

const UserButton: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()
  const { user, loginWithRedirect } = useAuth0()
  const { asPath } = useRouter()
  const { localePrefix } = useLocale()
  const [userMenuOpen, setUserMenuOpen] = useState(false)

  const returnToUrl = `${baseRedirectUri}${localePrefix}${asPath}`

  const handleLoginClick = () => {
    if (window.analytics) {
      window.analytics.track('Header Log in Clicked')
    }
    loginWithRedirect({
      authorizationParams: {
        redirect_uri: returnToUrl,
      },
    })
  }

  if (user) {
    return (
      <div className="relative">
        <button
          className={cn(
            'flex h-12 w-12 items-center justify-center rounded-full',
            { 'bg-white/20': userMenuOpen },
          )}
          onClick={() => setUserMenuOpen(!userMenuOpen)}
          aria-label="Toggle user menu"
        >
          {user.picture ? (
            <img
              width={50}
              height={50}
              src={user.picture}
              alt={user.name || 'User avatar'}
              className={s.avatar}
              referrerPolicy="no-referrer"
            />
          ) : (
            <UserIcon />
          )}
        </button>
        {userMenuOpen && <UserMenu />}
      </div>
    )
  }

  return (
    <button
      className={s.root}
      onClick={handleLoginClick}
      aria-label={t('navigation.sign_in') || ''}
    >
      <span className={s.label}>{t('navigation.sign_in')}</span>
      <UserIcon />
    </button>
  )

  // TODO - if this PR is going to be merged then we can delete the LOGIN2 env var
}

export default UserButton

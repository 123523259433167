import React, { FC, useEffect } from 'react'
import { Navbar, Footer } from '@components/common'
import { useAcceptCookies } from '@lib/hooks/useAcceptCookies'
import { Button } from '@components/ui'
import { LayoutProps } from '@commerce/types/layout'
import { useTranslation, Trans } from 'next-i18next'
import { NavBarProvider } from 'contexts/NavBarContext'
import cn from 'classnames'
import s from './Layout.module.css'
import FeatureBar from '@components/common/FeatureBar'
import Link from 'next/link'
import Drawers from '@components/common/Drawers'
import { useLocale } from '@lib/locales/utils'
import {
  setCountryModalRendered,
  useCountryPickerModal,
  shouldRenderCountryModal,
} from '@lib/country-picker'

interface Props {
  pageProps: {
    layout: LayoutProps
  }
}

// For i18n Trans component interpolation - TODO - find props for this callback https://react.i18next.com/latest/trans-component
const NextSingleComponentLink = (props: any) => (
  <Link href="/pages/privacy-policy" prefetch={false}>
    <a className="underline" {...props}></a>
  </Link>
)

const Layout: FC<React.PropsWithChildren<Props>> = ({
  children,
  pageProps: { layout = {} },
}) => {
  const { acceptedCookies, onAcceptCookies } = useAcceptCookies()
  const { locale } = useLocale()
  const { t } = useTranslation()
  const { openCountryPickerModal } = useCountryPickerModal()

  const hideUi = ['SubscribePage'].includes(layout.pageType || '')
  const showFooter = !['BlogArticle', 'AboutPage'].includes(
    layout.pageType || '',
  )

  useEffect(() => {
    const showCountryModal =
      !!layout.showLocaleModal && shouldRenderCountryModal(locale)

    if (showCountryModal) {
      openCountryPickerModal()
      setCountryModalRendered()
    }
  }, [layout.showLocaleModal, locale, openCountryPickerModal])
  return (
    <NavBarProvider>
      <div
        className={cn(s.root, {
          [s.pageCMS]: layout.pageType === 'CMS',
          [s.pageCampaign]: layout.pageType === 'CampaignPage',
          [s.pageAbout]: layout.pageType === 'AboutPage',
        })}
      >
        {!hideUi && <Navbar />}
        <main className="flex-grow">{children}</main>
        {!hideUi && showFooter && <Footer />}
        <>
          {!hideUi && <Drawers />}
          <FeatureBar
            title={
              <Trans
                i18nKey="feature_bar.description"
                t={t}
                components={[<NextSingleComponentLink key="anchor" />]}
              />
            }
            hide={acceptedCookies}
            layout={layout}
            action={
              <Button
                variant="secondary"
                className="mx-5"
                onClick={onAcceptCookies}
              >
                {t('feature_bar.button')}
              </Button>
            }
          />
        </>
      </div>
    </NavBarProvider>
  )
}

export default Layout

const SearchIcon = ({ ...props }) => {
  return (
    <svg width="19.717" height="20" viewBox="0 0 19.717 20" {...props}>
      <path
        d="M13.752,21.473a8.086,8.086,0,0,0,4.467-1.344l4.529,4.539a1.548,1.548,0,0,0,1.1.434,1.529,1.529,0,0,0,1.065-2.585L20.411,18a8.069,8.069,0,0,0,1.478-4.663,8.137,8.137,0,1,0-8.137,8.137Zm0-2.171a5.966,5.966,0,1,1,5.966-5.966A5.988,5.988,0,0,1,13.752,19.3Z"
        transform="translate(-5.615 -5.199)"
        fill="currentColor"
      />
    </svg>
  )
}

export default SearchIcon

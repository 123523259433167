const InstagramFlatIcon = ({ ...props }) => {
  return (
    <svg width="13.732" height="13.732" viewBox="0 0 13.732 13.732" {...props}>
      <title>Instagram</title>
      <path
        d="M7,3A4.01,4.01,0,0,0,3,7.006v5.722a4.01,4.01,0,0,0,4.006,4h5.722a4.01,4.01,0,0,0,4-4.006V7a4.01,4.01,0,0,0-4.006-4Zm6.867,2.289a.572.572,0,1,1-.572.572A.572.572,0,0,1,13.872,5.289ZM9.866,6.433A3.433,3.433,0,1,1,6.433,9.866,3.437,3.437,0,0,1,9.866,6.433Zm0,1.144a2.289,2.289,0,1,0,2.289,2.289A2.289,2.289,0,0,0,9.866,7.577Z"
        transform="translate(-3 -3)"
      />
    </svg>
  )
}

export default InstagramFlatIcon

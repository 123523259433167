const CartIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.196"
      height="19.902"
      viewBox="0 0 23.196 19.902"
      {...props}
    >
      <g
        id="Group_8043"
        data-name="Group 8043"
        transform="translate(-301.655 -27.219)"
      >
        <path
          id="Path_16705"
          data-name="Path 16705"
          d="M11.713,20.434H23.331a.893.893,0,1,0,0-1.787H11.943a.851.851,0,0,1-.848-.838l-.15-1.038H23.411a2.459,2.459,0,0,0,2.555-2.445l.749-5a3.138,3.138,0,0,0,.04-.409.976.976,0,0,0-1.1-.988h-16L9.507,6.85c-.13-.868-.479-1.308-1.587-1.308H4.5a.953.953,0,0,0,0,1.906H7.621l1.547,10.56A2.44,2.44,0,0,0,11.713,20.434ZM24.678,9.714l-.629,4.432c-.08.509-.349.828-.838.828l-12.526.01-.769-5.27ZM12.532,25.444A1.747,1.747,0,1,0,10.785,23.7,1.742,1.742,0,0,0,12.532,25.444Zm9.223,0A1.747,1.747,0,1,0,20,23.7,1.734,1.734,0,0,0,21.754,25.444Z"
          transform="translate(298.097 21.677)"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export default CartIcon

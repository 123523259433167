import { FC } from 'react'
import Link from 'next/link'
import MenuButton from './MenuButton'
import { Container } from '@components/ui'
import Searchbar from '@components/common/Searchbar'
import { useNavbarContext } from 'contexts/NavBarContext'
import CartButton from './CartButton'
import UserButton from './UserButton'
import { SesamyFullLogoIcon } from '@components/icons'
import SearchButton from './SearchButton'
import s from './Navbar.module.css'
import cn from 'classnames'

const Navbar: FC<React.PropsWithChildren<unknown>> = () => {
  const { setMainMenuOpen, searchAutocompleteShowing } = useNavbarContext()

  const openMenu = () => {
    setMainMenuOpen(true)
    if (window.analytics) {
      window.analytics.track('Opening Menu Drawer')
    }
  }

  const handleLogoClick = () => {
    if (window.analytics) {
      window.analytics.track('Header Logo Clicked')
    }
  }

  return (
    <div
      className={cn(s.root, {
        [s.autocompleteShowing]: searchAutocompleteShowing,
      })}
    >
      <Container>
        <div className={s.grid}>
          <div className={s.gridLeft}>
            <MenuButton onClick={openMenu} />
            <div className={s.divider}></div>
            <Link href="/?no-redirect=true" prefetch={false}>
              <a aria-label="Logo" onClick={handleLogoClick}>
                <SesamyFullLogoIcon className={s.logo} />
              </a>
            </Link>
          </div>
          <div className={s.gridRight}>
            <UserButton />
            <CartButton />
            <SearchButton />
          </div>
          <Searchbar />
        </div>
      </Container>
    </div>
  )
}

export default Navbar

const EbookIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.796"
      height="15.264"
      viewBox="0 0 17.796 15.264"
      {...props}
    >
      <path
        d="M12.922,6.665A5.487,5.487,0,0,0,8.893,5.12c-2.407,0-4.351,1.246-4.868,2.43V19.318a.976.976,0,0,0,1.058,1.066A1.384,1.384,0,0,0,6,20.062a4.355,4.355,0,0,1,2.893-.933,4.726,4.726,0,0,1,2.971.886,1.6,1.6,0,0,0,1.058.368,1.709,1.709,0,0,0,1.058-.368,4.958,4.958,0,0,1,2.971-.886,4.364,4.364,0,0,1,2.893.933,1.384,1.384,0,0,0,.917.321.976.976,0,0,0,1.058-1.066V7.55c-.517-1.184-2.454-2.43-4.868-2.43A5.5,5.5,0,0,0,12.922,6.665Zm-7.2,1.427A3.57,3.57,0,0,1,8.893,6.7a3.59,3.59,0,0,1,3.183,1.4v10.34a5.541,5.541,0,0,0-3.183-.894,5.279,5.279,0,0,0-3.167.917Zm14.394,0V18.455a5.279,5.279,0,0,0-3.167-.917,5.541,5.541,0,0,0-3.183.894V8.091a3.59,3.59,0,0,1,3.183-1.4A3.57,3.57,0,0,1,20.119,8.091Z"
        transform="translate(-4.024 -5.12)"
        fill="currentColor"
      />
    </svg>
  )
}

export default EbookIcon

const AudiobookIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.686"
      height="17.209"
      viewBox="0 0 18.686 17.209"
      {...props}
    >
      <path
        d="M5.334,18.682h.791a.208.208,0,0,0,.22-.22v-5.1C6.345,9.216,9.5,6.526,14,6.526s7.655,2.689,7.655,6.838v5.1a.208.208,0,0,0,.22.22h.791a.623.623,0,0,0,.677-.615V12.96c0-4.834-3.841-8.095-9.026-8.095h-.633c-5.186,0-9.026,3.261-9.026,8.095v5.106A.623.623,0,0,0,5.334,18.682ZM8.2,22.074H9.245a1.493,1.493,0,0,0,1.679-1.582V16.476a1.493,1.493,0,0,0-1.679-1.582H8.2a.826.826,0,0,0-.923.9v5.388A.827.827,0,0,0,8.2,22.074Zm10.564,0H19.81a.833.833,0,0,0,.932-.9V15.79a.829.829,0,0,0-.932-.9H18.764a1.493,1.493,0,0,0-1.679,1.582v4.017A1.493,1.493,0,0,0,18.764,22.074Z"
        transform="translate(-4.657 -4.865)"
        fill="currentColor"
      />
    </svg>
  )
}

export default AudiobookIcon

import { SVGProps } from 'react'

interface LinkedInFlatIconProps extends SVGProps<SVGSVGElement> {}

const LinkedInFlatIcon = ({ ...props }: LinkedInFlatIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13.727"
    height="13.697"
    viewBox="0 0 13.727 13.697"
    {...props}
  >
    <path
      d="M7.9,99.73H5.148a.221.221,0,0,0-.221.221V108.8a.221.221,0,0,0,.221.221H7.9a.221.221,0,0,0,.221-.221V99.951A.221.221,0,0,0,7.9,99.73Z"
      transform="translate(-4.709 -95.329)"
    />
    <path
      d="M1.818.341A1.817,1.817,0,1,0,3.635,2.157,1.819,1.819,0,0,0,1.818.341Z"
      transform="translate(0 -0.341)"
    />
    <path
      id="XMLID_804_"
      d="M111.679,94.761a3.237,3.237,0,0,0-2.421,1.016V95.2a.221.221,0,0,0-.221-.221H106.4a.221.221,0,0,0-.221.221v8.853a.221.221,0,0,0,.221.221h2.75a.221.221,0,0,0,.221-.221v-4.38c0-1.476.4-2.051,1.43-2.051,1.121,0,1.21.922,1.21,2.127v4.3a.221.221,0,0,0,.221.221h2.751a.221.221,0,0,0,.221-.221V99.2C115.2,97,114.783,94.761,111.679,94.761Z"
      transform="translate(-101.475 -90.579)"
    />
  </svg>
)

export default LinkedInFlatIcon

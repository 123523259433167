import { FC } from 'react'
import s from './MainMenuNav.module.css'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import MenuLoginButton from './MenuLoginButton'
import CountryPickerTrigger from '../CountryPickerTrigger/CountryPickerTrigger'

interface MainMenuNavProps {
  closeMobileMenu: () => void
  openCategoriesDrawer: () => void
}

const MainMenuNav: FC<React.PropsWithChildren<MainMenuNavProps>> = ({
  openCategoriesDrawer,
  closeMobileMenu,
}) => {
  const { t } = useTranslation()

  const router = useRouter()

  if (!router.isReady) {
    return null
  }

  const handleMenuItemClick = (label: string) => {
    closeMobileMenu()
    if (window.analytics) {
      window.analytics.track('Menu Item Clicked', {
        label,
      })
    }
  }
  const handleCategoriesClick = () => {
    openCategoriesDrawer()
    if (window.analytics) {
      window.analytics.track('Menu Item Clicked', {
        label: t('navigation.categories'),
      })
    }
  }

  return (
    <div className={s.root}>
      <nav className="mb-8">
        <Link href="/?no-redirect=true" prefetch={false}>
          <a
            onClick={() => handleMenuItemClick(t('navigation.store'))}
            className={s.link}
          >
            {t('navigation.store')}
          </a>
        </Link>
        <a className={s.link} onClick={handleCategoriesClick}>
          {t('navigation.categories')}
        </a>
        <Link href="/pages/about-sesamy" prefetch={false}>
          <a
            onClick={() => handleMenuItemClick(t('navigation.about_sesamy'))}
            className={s.link}
          >
            {t('navigation.about_sesamy')}
          </a>
        </Link>
        <a
          href={`https://support.sesamy.com/${router.locale}`}
          onClick={() => handleMenuItemClick(t('navigation.help'))}
          className={s.link}
        >
          {t('navigation.help')}
        </a>
        {process.env.NEXT_PUBLIC_SHOW_BUSINESSBANNER && (
          <a
            href="https://business.sesamy.com"
            className={s.link}
            onClick={() => handleMenuItemClick('Sesamy for Business')}
          >
            Sesamy for Business
          </a>
        )}
      </nav>
      <nav className="mb-12">
        <MenuLoginButton closeMobileMenu={closeMobileMenu} />
      </nav>
      <CountryPickerTrigger />
    </div>
  )
}

export default MainMenuNav

import { FC } from 'react'
import s from './MenuButton.module.css'

interface MenuButtonProps {
  onClick: () => void
}

const MenuButton: FC<React.PropsWithChildren<MenuButtonProps>> = ({
  onClick,
}) => {
  return (
    <button onClick={onClick} className={s.menuButton} aria-label="Navigation">
      <span></span>
      <span></span>
      <span></span>
    </button>
  )
}

export default MenuButton

import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  useDiscoveryQueryParams,
  DiscoveryQueryData,
  DiscoveryQueryResponse,
  DiscoveryQueryErrorResponse,
} from './types'
import { ParsedUrlQuery } from 'querystring'

// also on lib/checkout/go-to-checkout.ts - seems something we'll need more of with Sesamy APIs
// https://fettblog.eu/typescript-hasownproperty/
function hasOwnProperty<X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y,
): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop)
}

const DISCOVERY_API_URL = `${process.env.NEXT_PUBLIC_API_URL}/discovery`

const PAGE_SIZE = 48

export const THEMA_TAG_PREFIX = 'THEMA.'

const keyValueLucene = (key: string, value: string) => {
  return `${key}: "${value}"`
}

export const joinValuesForLuceneQuery = (
  key: string,
  value: string | string[],
) => {
  if (!Array.isArray(value)) {
    return keyValueLucene(key, value)
  }
  const subFilters = value.map((v) => keyValueLucene(key, v)).join(' OR ')
  return subFilters
}

type createLuceneQueryArgs = {
  initialFilter?: string
  productType?: string | string[] //  | string[] typing comes from next/router
  language: string | string[]
  categories: string | string[]
  vendor?: string | string[]
  q?: string | string[]
}

const createLuceneQuery = ({
  initialFilter,
  productType,
  language,
  categories,
  vendor,
  q,
}: createLuceneQueryArgs) => {
  const filters = initialFilter ? [initialFilter] : []

  if (!!q && !Array.isArray(q)) {
    const lowerCaseQuery = q.toLowerCase()
    // asterisk * is encoded below
    filters.push(`${lowerCaseQuery}*`)
  }

  if (!!productType) {
    const productFilter = joinValuesForLuceneQuery('productType', productType)
    filters.push(productFilter)
  }

  if (!!language) {
    const languageFilter = joinValuesForLuceneQuery('language', language)
    filters.push(languageFilter)
  }

  if (!!categories) {
    const categoriesFilter = joinValuesForLuceneQuery('categories', categories)
    filters.push(categoriesFilter)
  }

  if (!!vendor) {
    const vendorFilter = joinValuesForLuceneQuery('vendor_name', vendor)
    filters.push(vendorFilter)
  }

  if (filters.length > 0) {
    return filters.join(' AND ')
  }
  return null
}

type FetchSearchResultsArgs = {
  query: ParsedUrlQuery
  page: number
  initialFilter?: string
  limit?: number
}

const fetchSearchResults = async ({
  query,
  initialFilter,
  page,
  limit,
}: FetchSearchResultsArgs) => {
  const {
    q,
    product_type: productType,
    ['meta.sesamy.language']: language = '',
    categories = '',
    sort,
    vendor,
  } = query
  const querystring = createLuceneQuery({
    initialFilter,
    productType,
    language,
    categories,
    vendor,
    q,
  })

  // asterisk * url encoded is %2A - but browser helpers don't do this
  const luceneQuery = encodeURIComponent(querystring || '').replace(
    /[*]/g,
    '%2A',
  )

  const res = await fetch(
    `${DISCOVERY_API_URL}/search/v2?q=${luceneQuery}&limit=${
      limit ? limit : PAGE_SIZE
    }&start=${page * PAGE_SIZE}${sort ? `&sort=${sort}` : ''}`,
  )

  const resJSON: DiscoveryQueryResponse = await res.json()

  let data: DiscoveryQueryData | undefined = undefined
  let error: DiscoveryQueryErrorResponse | undefined = undefined

  // This pattern is something we'll want more if we have consistent Sesamy API error responses
  // by doing this everywhere, we should be able to prepare for lots of these errors by using TypeScript!
  if (hasOwnProperty(resJSON, 'error')) {
    // Latest version of typescript thinks this could be a `Record<"error", unknown>'`
    error = resJSON as DiscoveryQueryErrorResponse
  } else {
    data = resJSON
  }

  // copying useSWR
  return { data, error }
}

export const fetchDiscoveryAutocomplete = async (
  query: string,
  limit: number,
) => {
  const res = await fetchSearchResults({
    query: { q: query },
    page: 0,
    limit,
  })
  return res
}

// uses values in querystring
export const useDiscoveryQuery = (args: useDiscoveryQueryParams) => {
  const { initialFilter, defaultSorting } = args || {}

  const { query, isReady } = useRouter()
  const [page, setPage] = useState(0)

  const [data, setData] = useState<DiscoveryQueryData>()
  const [error, setError] = useState<DiscoveryQueryErrorResponse>()

  useEffect(() => {
    if (!isReady) {
      return
    }

    ;(async function () {
      if (defaultSorting && !query.sort) {
        query.sort = defaultSorting
      }
      const { data, error } = await fetchSearchResults({
        query,
        initialFilter,
        page: 0,
      })

      setData(data)
      setError(error)
    })()

    setPage(0)
  }, [query, initialFilter, isReady, defaultSorting])

  const hasNextPage = data && data.found > (page + 1) * PAGE_SIZE

  const getNextPage = async () => {
    if (!hasNextPage) return

    const { data: result, error } = await fetchSearchResults({
      query,
      initialFilter,
      page: page + 1,
    })

    if (result) {
      setData({
        ...data,
        hits: [...data.hits, ...result.hits],
      })
      setPage(page + 1)
    }

    if (error) {
      setError(error)
    }
  }

  return {
    data,
    error,
    getNextPage,
    hasNextPage,
  }
}

const AmexCardIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="49"
      height="27"
      viewBox="0 0 49 27"
      {...props}
    >
      <g transform="translate(-1080 -1323)">
        <rect
          width="49"
          height="27"
          rx="5"
          transform="translate(1080 1323)"
          fill="#26a6d1"
        />
        <g transform="translate(1085.663 1281.975)">
          <path
            d="M2.348,41.025H35.216a2.349,2.349,0,0,1,2.348,2.349v22.3a2.349,2.349,0,0,1-2.348,2.348H2.348A2.348,2.348,0,0,1,0,65.678v-22.3A2.349,2.349,0,0,1,2.348,41.025Z"
            fill="#26a6d1"
          />
          <path
            d="M21.945,113.966l-3.71,8.209h4.441l.551-1.309h1.258l.551,1.309h4.888v-1l.436,1h2.528l.436-1.02v1.02H43.489l1.236-1.275,1.157,1.275,5.221.011-3.721-4.092,3.721-4.127h-5.14l-1.2,1.251-1.121-1.251H32.58l-.95,2.119-.972-2.119H26.227v.965l-.493-.965Zm.859,1.166h2.165l2.461,5.567v-5.567H29.8l1.9,3.991,1.751-3.991h2.359v5.891H34.376l-.012-4.616-2.093,4.616H30.987l-2.1-4.616v4.616H25.929l-.56-1.321H22.344l-.559,1.319H20.2Zm14.321,0h5.838l1.785,1.929,1.843-1.929h1.785l-2.713,2.961,2.713,2.926H46.51l-1.785-1.951-1.852,1.951H37.125v-5.887Zm-13.268,1-1,2.353h1.992Zm14.71.223v1.075h3.185v1.2H38.567V119.8h3.572l1.66-1.729-1.589-1.72H38.567Z"
            transform="translate(-15.887 -63.55)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  )
}

export default AmexCardIcon

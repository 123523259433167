import cn from 'classnames'
import { useRouter } from 'next/router'
import { FC, useRef, useState, useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { ThemaTag } from '@commerce/types/thema-tag'
import s from './CategoriesDrawer.module.css'
import CategoryItem from '../CategoryItem'
import { getSelectedTags } from '@lib/themaTags/utils'
import useThemaTags from '@lib/hooks/useThemaTags'
import { Headline } from '@components/ui'

const CategoriesDrawer: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const { query } = router

  const category = Array.isArray(query.slug) ? query.slug[0] : query.slug

  const currentCategoryRef = useRef() as React.MutableRefObject<string>
  const themaTags = useThemaTags()

  const [selectedTags, setSelectedTags] = useState<(string | undefined)[]>([])
  const [hasSetMenuState, setSetMenuState] = useState(false)
  useEffect(() => {
    if (!themaTags) return
    if (!category) return
    if (currentCategoryRef.current === category && hasSetMenuState) return
    currentCategoryRef.current = category
    setSelectedTags(getSelectedTags(themaTags, currentCategoryRef.current))
    if (!hasSetMenuState) {
      setSetMenuState(true)
    }
  }, [category, themaTags, hasSetMenuState])

  const addSelectedTags = (tagsArray: (string | undefined)[]) => {
    const newSelectedTagsStateArray = selectedTags.slice()
    tagsArray.forEach((tag) => {
      if (newSelectedTagsStateArray.indexOf(tag) === -1) {
        newSelectedTagsStateArray.push(tag)
      }
    })
    setSelectedTags(newSelectedTagsStateArray)
  }

  return (
    <>
      <div className={s.categoriesDrawer} data-testid="categories-drawer">
        <div className={s.container}>
          <div className="py-6">
            <Headline Component="h3" variant="heading3">
              {t('drawer_header')}
            </Headline>
            <ul className={cn(s.categoryTree, s.list)}>
              {!!themaTags &&
                themaTags.map((themaTag: ThemaTag) => (
                  <CategoryItem
                    themaTag={themaTag}
                    key={themaTag.url}
                    activeCategory={category}
                    level={1}
                    onItemOpen={() => addSelectedTags([themaTag.url])}
                    onItemClose={() => {
                      setSelectedTags(
                        selectedTags.filter((url) => themaTag.url !== url),
                      )
                    }}
                    itemIsOpen={selectedTags.includes(themaTag.url)}
                  >
                    {themaTag.children &&
                      themaTag.children.map((themaTagChild: ThemaTag) => (
                        <CategoryItem
                          themaTag={themaTagChild}
                          key={themaTagChild.url}
                          activeCategory={category}
                          level={2}
                          onItemOpen={() =>
                            addSelectedTags([themaTagChild.url, themaTag.url])
                          }
                          onItemClose={() => {
                            setSelectedTags(
                              selectedTags.filter(
                                (url) => themaTagChild.url !== url,
                              ),
                            )
                          }}
                          itemIsOpen={selectedTags.includes(themaTagChild.url)}
                        >
                          {themaTagChild.children &&
                            themaTagChild.children.map(
                              (themaTagGrandchild: ThemaTag) => (
                                <CategoryItem
                                  themaTag={themaTagGrandchild}
                                  key={themaTagGrandchild.url}
                                  activeCategory={category}
                                  level={3}
                                  onItemOpen={() =>
                                    addSelectedTags([
                                      themaTagGrandchild.url,
                                      themaTagChild.url,
                                      themaTag.url,
                                    ])
                                  }
                                  onItemClose={() => {
                                    setSelectedTags(
                                      selectedTags.filter(
                                        (url) => themaTagGrandchild.url !== url,
                                      ),
                                    )
                                  }}
                                  itemIsOpen={selectedTags.includes(
                                    themaTagGrandchild.url,
                                  )}
                                ></CategoryItem>
                              ),
                            )}
                        </CategoryItem>
                      ))}
                  </CategoryItem>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default CategoriesDrawer

import { FC, ReactNode } from 'react'

interface FooterTextBlockProps {
  title: ReactNode
  content: ReactNode
}

const FooterTextBlock: FC<React.PropsWithChildren<FooterTextBlockProps>> = ({
  title,
  content,
}) => (
  <div className="flex flex-col">
    {typeof title === 'string' ? (
      <p className="mb-2.5 text-xs uppercase text-gray-300">{title}</p>
    ) : (
      <div className="mb-2.5">{title}</div>
    )}
    {content}
  </div>
)

export default FooterTextBlock

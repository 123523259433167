import { useState } from 'react'
import { useRouter } from 'next/router'
import { Button, Headline } from '@components/ui'
import { useModal } from '@contexts/ModalContext'
import { setUserLocale, getIpLocale, getUserLocale } from '@lib/country-picker'
import { getAllLocales, localeType, useLocale } from '@lib/locales/utils'
import CountryPicker from '../CountryPicker/CountryPicker'
import styles from './CountryPickerModalContent.module.css'

const getInitialLocale = () => getUserLocale() || getIpLocale()

const CountryPickerModalContent = () => {
  const router = useRouter()
  const { locale } = useLocale()
  const { closeModal } = useModal()
  const [selectedLocale, setLocale] = useState<localeType | null>(
    getInitialLocale(),
  )

  function handleCountryChange(locale: localeType) {
    setLocale(locale)
  }

  function handleContinueClick() {
    if (!selectedLocale) {
      return
    }

    setUserLocale(selectedLocale)
    closeModal()

    if (selectedLocale !== locale) {
      // We navigate to the home page of the new locale
      router.push(`/`, '/', { locale: selectedLocale })
    }
  }

  return (
    <>
      <div className={styles.wrapper} data-testid="country-picker-modal">
        <Headline Component="h3" variant="heading3" variantDesktop="heading2">
          Continue to store
        </Headline>
        <p className={styles.title}>
          Choose your location to explore in your local language.
        </p>
        <CountryPicker
          locales={getAllLocales()}
          value={selectedLocale}
          onChange={handleCountryChange}
        />
        <div className={styles.buttonWrapper}>
          <Button
            onClick={handleContinueClick}
            className={styles.button}
            disabled={!selectedLocale}
            aria-label="Continue"
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  )
}

export default CountryPickerModalContent

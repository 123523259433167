const IMG_S3BUCKET = process.env.NEXT_PUBLIC_IMAGE_S3BUCKET
const IMG_PROXY = process.env.NEXT_PUBLIC_IMAGE_PROXY

export const getImgProxyUrl = (imgPath: string, props?: string[]) => {
  if (imgPath.includes('images.sesamy.') && IMG_S3BUCKET) {
    imgPath = imgPath.replace(
      /https:\/\/images.sesamy.(?:dev|com)/i,
      IMG_S3BUCKET,
    )
  }
  const base64 = Buffer.from(imgPath).toString('base64')
  return `${IMG_PROXY}/unsafe/${props ? props.join('/') + '/' : ''}${base64}`
}

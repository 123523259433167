import cn from 'classnames'
import { FC, JSXElementConstructor } from 'react'

interface HeadlineProps {
  Component?: string | JSXElementConstructor<any>
  className?: string
  variant?:
    | 'display'
    | 'heading1'
    | 'heading2'
    | 'heading3'
    | 'subheading1'
    | 'subheading2'
    | 'subheading3'
    | 'subheading4'
  variantDesktop?:
    | 'display'
    | 'heading1'
    | 'heading2'
    | 'heading3'
    | 'subheading1'
    | 'subheading2'
    | 'subheading3'
    | 'subheading4'
}

const Headline: FC<React.PropsWithChildren<HeadlineProps>> = ({
  children,
  className,
  variant = 'heading1',
  variantDesktop,
  Component = 'div',
}) => {
  const rootClassName = cn(
    {
      ['text-6xl font-semibold']: variant === 'display',
      ['text-5xl font-semibold']: variant === 'heading1',
      ['text-4xl font-semibold']: variant === 'heading2',
      ['text-2xl font-semibold']: variant === 'heading3',
      ['text-3xl font-medium']: variant === 'subheading1',
      ['text-xl font-medium']: variant === 'subheading2',
      ['text-lg font-medium']: variant === 'subheading3',
      ['text-base font-medium']: variant === 'subheading4',
      ['md:text-6xl md:font-semibold']: variantDesktop === 'display',
      ['md:text-5xl md:font-semibold']: variantDesktop === 'heading1',
      ['md:text-4xl md:font-semibold']: variantDesktop === 'heading2',
      ['md:text-2xl md:font-semibold']: variantDesktop === 'heading3',
      ['md:text-3xl md:font-medium']: variantDesktop === 'subheading1',
      ['md:text-xl md:font-medium']: variantDesktop === 'subheading2',
      ['md:text-lg md:font-medium']: variantDesktop === 'subheading3',
      ['md:text-base md:font-medium']: variantDesktop === 'subheading4',
    },
    className,
  )
  return <Component className={rootClassName}>{children}</Component>
}

export default Headline

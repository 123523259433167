const SesamyFullLogoIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="151"
      height="30"
      viewBox="0 0 115.994 23"
      {...props}
    >
      <title>Sesamy</title>
      <g
        id="Group_9050"
        data-name="Group 9050"
        transform="translate(-92.753 -282)"
      >
        <g id="Group_9018" data-name="Group 9018" transform="translate(2 117)">
          <g
            id="Sesamy_Logotype_White"
            transform="translate(-216.713 -165.699)"
          >
            <g
              id="Group_8469"
              data-name="Group 8469"
              transform="translate(342.198 334.467)"
              opacity="0"
            >
              <path
                id="Path_16985"
                data-name="Path 16985"
                d="M419.316,344.319a4.21,4.21,0,0,0-2.536-.721,6.012,6.012,0,0,0-1.231.124,3.251,3.251,0,0,0-1.077.419,2.208,2.208,0,0,0-.751.782,2.426,2.426,0,0,0-.282,1.221,1.551,1.551,0,0,0,.406,1.117,3.031,3.031,0,0,0,1.087.7,8.984,8.984,0,0,0,1.536.449c.57.117,1.151.248,1.744.386s1.174.3,1.744.49a5.816,5.816,0,0,1,1.536.762,3.653,3.653,0,0,1,1.087,1.211,4.251,4.251,0,0,1-.127,3.875,4.4,4.4,0,0,1-1.369,1.379,5.891,5.891,0,0,1-1.868.772,9.2,9.2,0,0,1-2.056.242,8.786,8.786,0,0,1-2.372-.312,5.868,5.868,0,0,1-1.962-.949,4.479,4.479,0,0,1-1.335-1.63,5.223,5.223,0,0,1-.49-2.348h1.879a3.245,3.245,0,0,0,1.325,2.737,4.014,4.014,0,0,0,1.389.647,6.315,6.315,0,0,0,1.63.208,7.388,7.388,0,0,0,1.349-.124,3.9,3.9,0,0,0,1.221-.429,2.4,2.4,0,0,0,.876-.825,2.454,2.454,0,0,0,.335-1.335,1.875,1.875,0,0,0-.406-1.255,3,3,0,0,0-1.087-.782,7.878,7.878,0,0,0-1.536-.49c-.57-.124-1.154-.255-1.744-.386s-1.174-.285-1.744-.46a6.36,6.36,0,0,1-1.536-.688A3.3,3.3,0,0,1,411.862,348a3.258,3.258,0,0,1-.406-1.7,3.962,3.962,0,0,1,.46-1.952,4.027,4.027,0,0,1,1.221-1.359,5.472,5.472,0,0,1,1.734-.8,7.633,7.633,0,0,1,2-.262,7.964,7.964,0,0,1,2.13.272,4.864,4.864,0,0,1,1.724.855,4.15,4.15,0,0,1,1.171,1.473,5.241,5.241,0,0,1,.47,2.12h-1.879A3.137,3.137,0,0,0,419.316,344.319Z"
                transform="translate(-411 -341.93)"
                fill="#fff"
              />
              <path
                id="Path_16986"
                data-name="Path 16986"
                d="M460.945,342.973v1.671h-8.313v4.74h7.749v1.671h-7.749v5.159h8.376v1.671H450.65V342.97h10.3Z"
                transform="translate(-437.349 -342.621)"
                fill="#fff"
              />
              <path
                id="Path_16987"
                data-name="Path 16987"
                d="M492.976,344.319a4.21,4.21,0,0,0-2.536-.721,6.012,6.012,0,0,0-1.231.124,3.251,3.251,0,0,0-1.077.419,2.207,2.207,0,0,0-.751.782,2.426,2.426,0,0,0-.282,1.221,1.552,1.552,0,0,0,.406,1.117,3.032,3.032,0,0,0,1.087.7,8.989,8.989,0,0,0,1.536.449c.57.117,1.151.248,1.744.386s1.174.3,1.744.49a5.818,5.818,0,0,1,1.536.762,3.654,3.654,0,0,1,1.087,1.211,4.251,4.251,0,0,1-.127,3.875,4.4,4.4,0,0,1-1.369,1.379,5.892,5.892,0,0,1-1.869.772,9.2,9.2,0,0,1-2.056.242,8.786,8.786,0,0,1-2.372-.312,5.867,5.867,0,0,1-1.962-.949,4.478,4.478,0,0,1-1.335-1.63,5.222,5.222,0,0,1-.49-2.348h1.879a3.245,3.245,0,0,0,1.325,2.737,4.014,4.014,0,0,0,1.389.647,6.315,6.315,0,0,0,1.63.208,7.387,7.387,0,0,0,1.349-.124,3.9,3.9,0,0,0,1.221-.429,2.4,2.4,0,0,0,.876-.825,2.455,2.455,0,0,0,.335-1.335,1.874,1.874,0,0,0-.406-1.255,3,3,0,0,0-1.087-.782,7.875,7.875,0,0,0-1.536-.49c-.57-.124-1.154-.255-1.744-.386s-1.174-.285-1.744-.46a6.357,6.357,0,0,1-1.536-.688A3.3,3.3,0,0,1,485.522,348a3.256,3.256,0,0,1-.406-1.7,3.962,3.962,0,0,1,.46-1.952,4.028,4.028,0,0,1,1.221-1.359,5.473,5.473,0,0,1,1.734-.8,7.635,7.635,0,0,1,2-.262,7.964,7.964,0,0,1,2.13.272,4.863,4.863,0,0,1,1.724.855,4.152,4.152,0,0,1,1.171,1.473,5.241,5.241,0,0,1,.47,2.12h-1.879A3.148,3.148,0,0,0,492.976,344.319Z"
                transform="translate(-459.95 -341.93)"
                fill="#fff"
              />
              <path
                id="Path_16988"
                data-name="Path 16988"
                d="M528.561,342.98l5.827,14.915h-2.194l-1.631-4.492h-6.307l-1.671,4.492H520.56l5.807-14.915Zm1.379,8.752-2.486-6.954h-.04l-2.526,6.954Z"
                transform="translate(-483.807 -342.628)"
                fill="#fff"
              />
              <path
                id="Path_16989"
                data-name="Path 16989"
                d="M567.5,342.98l4.7,12.533,4.72-12.533h2.714v14.915h-1.879V345.489h-.04l-4.656,12.405h-1.691l-4.656-12.405h-.04v12.405H564.79V342.98Z"
                transform="translate(-513.2 -342.628)"
                fill="#fff"
              />
              <path
                id="Path_16990"
                data-name="Path 16990"
                d="M620.9,357.9h-1.986v-6.1l-5.763-8.813h2.362l4.468,7.1,4.364-7.1H626.6l-5.7,8.813v6.1Z"
                transform="translate(-545.337 -342.635)"
                fill="#fff"
              />
            </g>
            <g
              id="Sesamy_symbol_white_RGB"
              transform="translate(307.461 330.699)"
            >
              <g
                id="Group_8998"
                data-name="Group 8998"
                transform="translate(4.101)"
              >
                <path
                  id="Path_17242"
                  data-name="Path 17242"
                  d="M52.744,1.15H34.775a.575.575,0,1,1,0-1.15H52.744a.575.575,0,1,1,0,1.15Z"
                  transform="translate(-34.2)"
                  fill="#fff"
                />
              </g>
              <g
                id="Group_8999"
                data-name="Group 8999"
                transform="translate(6.717 8.843)"
              >
                <path
                  id="Path_17243"
                  data-name="Path 17243"
                  d="M69.35,79.17a.6.6,0,0,1-.181-.029l-12.737-4.2a.575.575,0,0,1,.361-1.092l12.739,4.2a.575.575,0,0,1-.181,1.121Z"
                  transform="translate(-56.037 -73.818)"
                  fill="#fff"
                />
              </g>
              <g
                id="Group_9000"
                data-name="Group 9000"
                transform="translate(0.005 2.317)"
              >
                <path
                  id="Path_17244"
                  data-name="Path 17244"
                  d="M19.268,22.43a.518.518,0,0,1-.06,0L.52,20.49a.575.575,0,0,1,.119-1.144l18.687,1.936a.575.575,0,0,1-.059,1.148Z"
                  transform="translate(-0.005 -19.343)"
                  fill="#fff"
                />
              </g>
              <g
                id="Group_9001"
                data-name="Group 9001"
                transform="translate(2.307 5.099)"
              >
                <path
                  id="Path_17245"
                  data-name="Path 17245"
                  d="M33.569,46.36a.57.57,0,0,1-.109-.011L19.69,43.708a.575.575,0,0,1,.217-1.13l13.77,2.641a.575.575,0,0,1-.108,1.14Z"
                  transform="translate(-19.223 -42.569)"
                  fill="#fff"
                />
              </g>
              <g
                id="Group_9002"
                data-name="Group 9002"
                transform="translate(4.034 21.85)"
              >
                <path
                  id="Path_17246"
                  data-name="Path 17246"
                  d="M34.215,182.4H52.184a.575.575,0,1,1,0,1.15H34.215a.575.575,0,1,1,0-1.15Z"
                  transform="translate(-33.64 -182.4)"
                  fill="#fff"
                />
              </g>
              <g
                id="Group_9003"
                data-name="Group 9003"
                transform="translate(7.478 17.625)"
              >
                <path
                  id="Path_17247"
                  data-name="Path 17247"
                  d="M62.969,147.13a.518.518,0,0,1,.06,0l18.687,1.936a.575.575,0,1,1-.119,1.144L62.91,148.278a.575.575,0,0,1,.059-1.148Z"
                  transform="translate(-62.395 -147.13)"
                  fill="#fff"
                />
              </g>
              <g
                id="Group_9004"
                data-name="Group 9004"
                transform="translate(10.093 14.108)"
              >
                <path
                  id="Path_17248"
                  data-name="Path 17248"
                  d="M84.8,117.77a.57.57,0,0,1,.109.011l13.771,2.641a.575.575,0,0,1-.217,1.13L84.69,118.909a.575.575,0,0,1,.108-1.139Z"
                  transform="translate(-84.223 -117.77)"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </g>
        <g
          id="Group_9021"
          data-name="Group 9021"
          transform="translate(124.916 285.203)"
        >
          <path
            id="Path_16985-2"
            data-name="Path 16985"
            d="M419.316,344.319a4.21,4.21,0,0,0-2.536-.721,6.012,6.012,0,0,0-1.231.124,3.251,3.251,0,0,0-1.077.419,2.208,2.208,0,0,0-.751.782,2.426,2.426,0,0,0-.282,1.221,1.551,1.551,0,0,0,.406,1.117,3.031,3.031,0,0,0,1.087.7,8.984,8.984,0,0,0,1.536.449c.57.117,1.151.248,1.744.386s1.174.3,1.744.49a5.816,5.816,0,0,1,1.536.762,3.653,3.653,0,0,1,1.087,1.211,4.251,4.251,0,0,1-.127,3.875,4.4,4.4,0,0,1-1.369,1.379,5.891,5.891,0,0,1-1.868.772,9.2,9.2,0,0,1-2.056.242,8.786,8.786,0,0,1-2.372-.312,5.868,5.868,0,0,1-1.962-.949,4.479,4.479,0,0,1-1.335-1.63,5.223,5.223,0,0,1-.49-2.348h1.879a3.245,3.245,0,0,0,1.325,2.737,4.014,4.014,0,0,0,1.389.647,6.315,6.315,0,0,0,1.63.208,7.388,7.388,0,0,0,1.349-.124,3.9,3.9,0,0,0,1.221-.429,2.4,2.4,0,0,0,.876-.825,2.454,2.454,0,0,0,.335-1.335,1.875,1.875,0,0,0-.406-1.255,3,3,0,0,0-1.087-.782,7.878,7.878,0,0,0-1.536-.49c-.57-.124-1.154-.255-1.744-.386s-1.174-.285-1.744-.46a6.36,6.36,0,0,1-1.536-.688A3.3,3.3,0,0,1,411.862,348a3.258,3.258,0,0,1-.406-1.7,3.962,3.962,0,0,1,.46-1.952,4.027,4.027,0,0,1,1.221-1.359,5.472,5.472,0,0,1,1.734-.8,7.633,7.633,0,0,1,2-.262,7.964,7.964,0,0,1,2.13.272,4.864,4.864,0,0,1,1.724.855,4.15,4.15,0,0,1,1.171,1.473,5.241,5.241,0,0,1,.47,2.12h-1.879A3.137,3.137,0,0,0,419.316,344.319Z"
            transform="translate(-411 -341.93)"
            fill="#fff"
          />
          <path
            id="Path_16986-2"
            data-name="Path 16986"
            d="M460.945,342.973v1.671h-8.313v4.74h7.749v1.671h-7.749v5.159h8.376v1.671H450.65V342.97h10.3Z"
            transform="translate(-437.349 -342.621)"
            fill="#fff"
          />
          <path
            id="Path_16987-2"
            data-name="Path 16987"
            d="M492.976,344.319a4.21,4.21,0,0,0-2.536-.721,6.012,6.012,0,0,0-1.231.124,3.251,3.251,0,0,0-1.077.419,2.207,2.207,0,0,0-.751.782,2.426,2.426,0,0,0-.282,1.221,1.552,1.552,0,0,0,.406,1.117,3.032,3.032,0,0,0,1.087.7,8.989,8.989,0,0,0,1.536.449c.57.117,1.151.248,1.744.386s1.174.3,1.744.49a5.818,5.818,0,0,1,1.536.762,3.654,3.654,0,0,1,1.087,1.211,4.251,4.251,0,0,1-.127,3.875,4.4,4.4,0,0,1-1.369,1.379,5.892,5.892,0,0,1-1.869.772,9.2,9.2,0,0,1-2.056.242,8.786,8.786,0,0,1-2.372-.312,5.867,5.867,0,0,1-1.962-.949,4.478,4.478,0,0,1-1.335-1.63,5.222,5.222,0,0,1-.49-2.348h1.879a3.245,3.245,0,0,0,1.325,2.737,4.014,4.014,0,0,0,1.389.647,6.315,6.315,0,0,0,1.63.208,7.387,7.387,0,0,0,1.349-.124,3.9,3.9,0,0,0,1.221-.429,2.4,2.4,0,0,0,.876-.825,2.455,2.455,0,0,0,.335-1.335,1.874,1.874,0,0,0-.406-1.255,3,3,0,0,0-1.087-.782,7.875,7.875,0,0,0-1.536-.49c-.57-.124-1.154-.255-1.744-.386s-1.174-.285-1.744-.46a6.357,6.357,0,0,1-1.536-.688A3.3,3.3,0,0,1,485.522,348a3.256,3.256,0,0,1-.406-1.7,3.962,3.962,0,0,1,.46-1.952,4.028,4.028,0,0,1,1.221-1.359,5.473,5.473,0,0,1,1.734-.8,7.635,7.635,0,0,1,2-.262,7.964,7.964,0,0,1,2.13.272,4.863,4.863,0,0,1,1.724.855,4.152,4.152,0,0,1,1.171,1.473,5.241,5.241,0,0,1,.47,2.12h-1.879A3.148,3.148,0,0,0,492.976,344.319Z"
            transform="translate(-459.95 -341.93)"
            fill="#fff"
          />
          <path
            id="Path_16988-2"
            data-name="Path 16988"
            d="M528.561,342.98l5.827,14.915h-2.194l-1.631-4.492h-6.307l-1.671,4.492H520.56l5.807-14.915Zm1.379,8.752-2.486-6.954h-.04l-2.526,6.954Z"
            transform="translate(-483.807 -342.628)"
            fill="#fff"
          />
          <path
            id="Path_16989-2"
            data-name="Path 16989"
            d="M567.5,342.98l4.7,12.533,4.72-12.533h2.714v14.915h-1.879V345.489h-.04l-4.656,12.405h-1.691l-4.656-12.405h-.04v12.405H564.79V342.98Z"
            transform="translate(-513.2 -342.628)"
            fill="#fff"
          />
          <path
            id="Path_16990-2"
            data-name="Path 16990"
            d="M620.9,357.9h-1.986v-6.1l-5.763-8.813h2.362l4.468,7.1,4.364-7.1H626.6l-5.7,8.813v6.1Z"
            transform="translate(-545.337 -342.635)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  )
}

export default SesamyFullLogoIcon

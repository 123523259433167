import { useState, useContext, createContext, FC, useEffect } from 'react'
import type { LineItem } from '@commerce/types/cart'
import type { DiscoveryProductProduct } from '@commerce/types/product'
import { useRouter } from 'next/router'
import { convertCurrency } from '@commerce/product/use-price'
import { useLocale } from '@lib/locales/utils'

type AddItemArgs = {
  productId: string
  sesamyId?: string
  isbn?: string
  // adding new args here
  product: DiscoveryProductProduct
}

/* eslint-disable no-unused-vars */
type CartContextType = {
  addItem: (arg0: AddItemArgs) => void
  removeItem: (arg0: LineItem) => void
  isEmpty: boolean
  data: {
    lineItems: LineItem[]
    totalPrice: number
    subtotalPrice: number
    currency: {
      code: string
    }
    discountCode?: string
  }
}
/* eslint-enable camelcase */

// for type script
function removeNulls(value: LineItem | null): value is LineItem {
  return value != null
}

const createKey = (locale: string) => `sesamy-cart-line-items-${locale}`
const getLocalStorageKey = (locale: string) => {
  // Swedish and International are the same on Shopify
  if (locale === 'en') {
    return createKey('sv')
  }
  // no change needed here for future locales
  return createKey(locale)
}

const getInitialItemsFromLocalStorage = (locale: string) => {
  if (typeof window !== 'undefined' && !!window.localStorage) {
    const localStorageValue = window.localStorage.getItem(
      getLocalStorageKey(locale),
    )
    if (!localStorageValue) {
      return []
    }
    try {
      const cart: LineItem[] = JSON.parse(localStorageValue)

      // Hot fix 8th August -> we have old ID's here for old carts. Remove these if they exist
      const cleanCart = cart
        .map((item) => {
          if (item.id.includes(':')) {
            return item
          }
          return null
        })
        .filter(removeNulls)

      return cleanCart
    } catch (e) {
      return []
    }
  }
  return []
}

const CartContext = createContext<CartContextType>({} as CartContextType)

const CartProvider: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { query, isReady } = useRouter()
  const { locale, countryCode } = useLocale()

  const { discount } = query

  const [items, setItemsInternalState] = useState<LineItem[]>([])
  const [discountCode, setDiscountCode] = useState<string>()

  // only set cart state on first render so don't get hydration issues
  useEffect(() => {
    // TODO - should check here if items are empty so don't set cart state multiple times?
    const initialLineItems = getInitialItemsFromLocalStorage(locale)
    setItemsInternalState(initialLineItems)
  }, [locale])

  useEffect(() => {
    if (isReady) {
      if (discount) {
        const singleValue = Array.isArray(discount) ? discount[0] : discount
        setDiscountCode(singleValue)
      }
    }
  }, [isReady, discount])

  useEffect(() => {
    if (!isReady) {
      return
    }

    // Could just check it's truthy...
    if (query['clear-cart'] === 'true') {
      if (!!window.localStorage) {
        // TODO - remove local storage from all locales?!!
        window.localStorage.removeItem(getLocalStorageKey(locale))
      }

      // now clear state
      setItemsInternalState([])
    }
  }, [locale, isReady, countryCode, query])

  const allPrices = items.map((i) => i.variant!.price)

  const allPricesInLocale = allPrices.map((price) => {
    if (locale === 'da') {
      return convertCurrency({
        amount: price,
        currencyCode: 'DKK',
      })
    } else {
      return price
    }
  })

  const totalPrice = allPricesInLocale.reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0,
  )

  const setItems = (items: LineItem[]) => {
    if (typeof window !== 'undefined' && !!window.localStorage) {
      window.localStorage.setItem(
        getLocalStorageKey(locale),
        JSON.stringify(items),
      )
    }
    setItemsInternalState(items)
  }

  const value = {
    addItem: ({ productId, sesamyId, isbn, product }: AddItemArgs) => {
      const image = product.cover
        ? {
            url: product.cover,
            alt: product.title,
          }
        : {
            alt: 'No image',
            url: '/product-img-placeholder.png',
          }

      const path = product.slug

      // initially taken from normalizeLineItem - framework/shopify/utils/normalize.ts - can now change as we're off Shopify
      const newLineItem: LineItem = {
        id: productId,
        name: product.title,
        quantity: 1,
        discounts: [],
        path,
        productType: product.productType,
        // all based on normalizeLineItem
        variant: {
          sku: product.sku || '',
          name: product.title,
          // convert from cents to maintain current functionality. will break existing carts otherwise
          price: product.prices.SE / 100,
          // TODO - why have both this if is the same?
          listPrice: product.prices.SE / 100,
          image,
        },
        vendor: product.vendorName,
        customAttributes: {
          isbn,
          sesamyId,
        },
      }

      setItems([...items, newLineItem])
    },
    removeItem: (item: LineItem) => {
      const newItems = items.filter((i) => i.id !== item.id)
      setItems(newItems)
    },
    isEmpty: items.length === 0,
    data: {
      lineItems: items,
      totalPrice,
      subtotalPrice: totalPrice,
      currency: {
        code: 'SEK',
      },
      discountCode,
    },
  }

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}

function useCart() {
  const context = useContext(CartContext)
  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider')
  }
  return context
}

export { CartProvider, useCart }
